import axios from 'axios';
import { isAndWebView, isIOSWebView } from './constants';

const api = axios.create({
  baseURL: process.env.REACT_APP_PSP_API, //defualt URL .env에 설정
  headers: { 'Cache-Control': 'no-cache' },
});

// psp_id가 sessionStorage에 없는 경우 앱에서 조회
const getCpspId = async () => {
  const callbackName = 'savePspId';
  window[callbackName] = (cpsp_id, id) => {
    localStorage.setItem(cpsp_id, id);
  };

  try {
    if (isIOSWebView) {
      webkit.messageHandlers.getKeyValue.postMessage({
        key: 'psp_id',
        callback: callbackName,
      });
    } else if (isAndWebView) {
      window.binder?.getKeyValue('psp_id', callbackName);
    }
  } catch (e) {
    console.log('bridge error getKeyValue');
  }
};

//앱 callback function 호출 시까지 대기
const checkedId = () => {
  return new Promise(resolve => {
    const timerId = setInterval(() => {
      const _id = localStorage.psp_id;
      if (_id) {
        clearInterval(timerId);
        resolve(_id);
      }
    }, 500);
  });
};

// 요청 인터셉터
api.interceptors.request.use(
  async config => {
    let psp_id = localStorage.psp_id;

    // GO코인 식별자 캐시삭제 된 경우 세팅
    if (config.method === 'get' && config.params) {
      if (!psp_id) {
        getCpspId();
        const newId = await checkedId(); //앱에서 식별자 조회시까지 대기
        psp_id = newId;
      }
      config.params.id = psp_id;
    } else if (config.method === 'post' && config.data) {
      if (!psp_id) {
        getCpspId();
        const newId = await checkedId(); //앱에서 식별자 조회시까지 대기
        psp_id = newId;
      }
      config.data.id = psp_id;
    }

    // 스노우플랜 API 헤더 세팅
    config.headers['x-ssp-token'] = process.env.REACT_APP_PSP_API_TOKEN;
    config.headers['x-ssp-channel'] = 'CPSP_FO';
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    return config;
  },
  error => {
    // 오류 요청을 보내기전 수행할 일
    // ...
    return Promise.reject(error);
  },
);

// 응답 인터셉터
api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default api;
