import axios from 'axios';
import { getMartgoTkn } from './sso';
import { apiUserSsoTokenRefresh } from 'src/api/apiUserSso';
import {
  bridgeDeleteCache,
  bridgeGetToken,
  bridgeLogout,
  bridgeSetAutoLogin,
} from './bridge';
import { isAndWebView, isIOSWebView } from './constants';

/**
 * @name 공통API 호출 유틸
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
});

/**
 * @name 토큰조회체크 앱에서 조회한 토큰이 storage에 저장되었는지 체크
 */
const checkedToken = () => {
  return new Promise(resolve => {
    const timerId = setInterval(() => {
      const martgoTkn = getMartgoTkn();
      const tknNull = localStorage.getItem('getLoginInfo_null');
      if (martgoTkn && martgoTkn.access_token) {
        clearInterval(timerId);
        resolve(martgoTkn.access_token);
      } else if (tknNull === 'Y') {
        //앱DB에 마트GO 토큰이 없는 경우 종료
        clearInterval(timerId);
        resolve('');
      }
    }, 500);
  });
};

// 요청 인터셉터
api.interceptors.request.use(
  async config => {
    const objMartgoTkn = getMartgoTkn();
    let access_token = objMartgoTkn && objMartgoTkn.access_token;
    const refresh_token = objMartgoTkn && objMartgoTkn.refresh_token;
    /**
     * @name 공통API headers 세팅
     * @param x-ssp-token 로그인 시 저장한 접근토큰
     * @param x-ssp-refresh-token 로그인 시 저장한 갱신토큰
     * @param x-ssp-channel 접근 채널 (롯데마트GO:1 / 롯데마트BO:2 / 보틀벙커:3 / 보틀벙커BO:4 / POS:5)
     * @param x-ssp-gateway-service-id 접근 서비스 (USER/COUPON/EVENT/CLUB/PUSH/SCANGO/PRODUCT/BOTTLE)
     * @description x-ssp-gateway-service-id는 호출 시 헤더에 별도 세팅 필요. E_API_SERVICEID 사용
     */

    //토큰없이 조회하는 화면 or api의 경우 토큰 조회 제외
    const isNotTokenPage =
      location.pathname.indexOf('/login') > -1 ||
      location.pathname.indexOf('/signup') > -1 ||
      location.pathname.indexOf('/system') > -1 ||
      SKIP_ALL_API.indexOf(config.url || '') > -1 ||
      //AS-IS 업데이트 후 api 호출 시 마트GO 토큰 없음 - 예외처리
      localStorage.getItem('AUTO_LOGIN_STATE') === 'true'
        ? true
        : false;

    if (!access_token && !isNotTokenPage && (isAndWebView || isIOSWebView)) {
      //스토리지에 토큰 없는 경우 앱에서 토큰 조회
      bridgeGetToken();
      const newToken = await checkedToken(); //앱에서 새로운 토큰 발급시까지 대기
      access_token = newToken;
    }

    if (access_token) {
      config.headers['x-ssp-token'] = access_token || '';
      config.headers['x-ssp-refresh-token'] = refresh_token || '';
      config.headers['x-ssp-channel'] = '1';
      config.headers['Content-Type'] = 'application/json; charset=utf-8';
    } else {
      //앱DB에도 토큰이 없는 경우 - 토큰이 없다는 구분 값 초기화
      localStorage.setItem('getLoginInfo_null', '');
    }
    return config;
  },
  async error => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터
api.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    const objMartgoTkn = getMartgoTkn();
    const access_token = objMartgoTkn && objMartgoTkn.access_token;
    const status = error.response && error.response.status;
    const config = error.config;
    if (status === 401) {
      //토큰 만료 시 갱신
      if (
        access_token &&
        config.url !== '/tokens/token-refresh' &&
        config.baseURL === process.env.REACT_APP_BASE_API
      ) {
        const newTokenData = await apiUserSsoTokenRefresh();

        if (newTokenData) {
          config.headers['x-ssp-token'] = newTokenData.token;
          config.headers['x-ssp-refresh-token'] = newTokenData.refreshToken;

          return api.request(config);
        } else {
          return Promise.reject(error);
        }
      } else {
        //401에러일때 마트GO 토큰이 없으면 캐시/토큰삭제 - 로그아웃 처리
        bridgeSetAutoLogin('N');
        bridgeLogout();
        bridgeDeleteCache('');
        localStorage.clear();
        sessionStorage.clear();
        location.href = '/login';

        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export default api;

/**
 * @name 토큰없이 조회하는 API 목록
 */
const SKIP_ALL_API = [
  '/fo/user/sso/login/auto',
  '/fo/user/sso/login/auto-go',
  '/fo/event/main/popup/sys/list',
  '/fo/event/main/popup/splash',
  '/fo/user/main/region/list',
  '/fo/user/main/store/list',
  '/fo/user/common/current-time',
  '/fo/user/sso/login/lpoint',
  '/fo/user/sso/login/social',
  '/fo/user/sso/login/easy',
  '/fo/user/sso/login/apple-info',
  '/fo/user/sso/signup/sso-agmt',
  '/fo/user/sso/signup',
  '/fo/user/sso/signup/agmt/list',
  '/fo/user/common/lang',
];
