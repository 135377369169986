import { action } from 'typesafe-actions';
import ActionTypes from '../constants/appConstants';
import {
  TAlertPopupProps,
  TConfirmPopupProps,
  TDeviceInfo,
  TTermsPopupProps,
} from '../types/appTypes';
import {
  TAdvertisingPush,
  TBanner,
  TNoticePush,
  TSysPopup,
} from 'src/types/types';

export type AppAction =
  | {
      type: typeof ActionTypes.OPEN_CONFIRM_POPUP;
      payload: {
        confirmPopupState: TConfirmPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_CONFIRM_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_APPCARD_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_APPCARD_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_SNOWPOINT_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_SNOWPOINT_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_ALERT_POPUP;
      payload: {
        alertPopupState: TAlertPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_ALERT_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_TERMS_POPUP;
      payload: {
        termsPopupState: TTermsPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_TERMS_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_MAIN_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_MAIN_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_SNOWPLAN_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_SNOWPLAN_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_WELCOME_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_WELCOME_POPUP;
    }
  | {
      type: typeof ActionTypes.INIT_WELCOME_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_LOADING_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_LOADING_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_INTRO_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_INTRO_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_START_SCANGO;
    }
  | {
      type: typeof ActionTypes.CLOSE_START_SCANGO;
    }
  | {
      type: typeof ActionTypes.APP_DEVICE_INFO;
      payload: {
        deviceInfo: TDeviceInfo;
      };
    }
  | {
      type: typeof ActionTypes.GET_MERGED_PUSH_LIST;
      payload: {
        mergedPushList: (TAdvertisingPush | TNoticePush)[];
      };
    }
  | {
      type: typeof ActionTypes.GET_SCANGO_BANNER;
      payload: {
        scangoBannerList: TBanner[];
      };
    }
  | {
      type: typeof ActionTypes.OPEN_SCANGO_INTRO_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_SCANGO_INTRO_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_MART_SETTING_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_MART_SETTING_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_INSPECTION_APPCARD_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_INSPECTION_APPCARD_POPUP;
    }
  | {
      type: typeof ActionTypes.IS_HIDE_SPLASH;
    }
  | {
      type: typeof ActionTypes.SET_SYSTEM_INSPECTION_POPUP_LIST;
      payload: {
        inspectionPopupList: TSysPopup[];
      };
    }
  | {
      type: typeof ActionTypes.SET_SYSTEM_UPDATE_POPUP_LIST;
      payload: {
        updatePopupList: TSysPopup[];
      };
    }
  | {
      type: typeof ActionTypes.SET_MDPICK_YN;
      payload: {
        mdpickYn: string;
      };
    }
  | {
      type: typeof ActionTypes.OPEN_SHARECARD_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_SHARECARD_POPUP;
    };

/**
 * @name 확인/취소 팝업 열기
 */
export const actionOpenConfirmPopup = (
  confirmPopupState: TConfirmPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_CONFIRM_POPUP, { confirmPopupState });
};

/**
 * @name 확인/취소 팝업 닫기
 */
export const actionCloseConfirmPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_CONFIRM_POPUP);
};

/**
 * @name 앱카드 팝업 열기
 */
export const actionOpenAppCardPopup = (): AppAction => {
  return action(ActionTypes.OPEN_APPCARD_POPUP);
};

/**
 * @name 앱카드 팝업 닫기
 */
export const actionCloseAppCardPouup = (): AppAction => {
  return action(ActionTypes.CLOSE_APPCARD_POPUP);
};
/**
 * @name 스노우플랜사용하기 팝업 열기
 */
export const actionOpenSnowPlanUsePopup = (): AppAction => {
  return action(ActionTypes.OPEN_SNOWPOINT_POPUP);
};

/**
 * @name 스노우플랜사용하기 팝업 닫기
 */
export const actionCloseSnowPlanUsePopup = (): AppAction => {
  return action(ActionTypes.CLOSE_SNOWPOINT_POPUP);
};

/**
 * @name 알림 팝업 열기
 */
export const actionOpenAlertPopup = (
  alertPopupState: TAlertPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_ALERT_POPUP, { alertPopupState });
};

/**
 * @name 알림 팝업 닫기
 */
export const actionCloseAlertPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_ALERT_POPUP);
};

/**
 * @name 약관상세 팝업 열기
 */
export const actionOpenTermsPopup = (
  termsPopupState: TTermsPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_TERMS_POPUP, { termsPopupState });
};

/**
 * @name 약관상세 팝업 닫기
 */
export const actionCloseTermsPouup = (): AppAction => {
  return action(ActionTypes.CLOSE_TERMS_POPUP);
};

/**
 * @name 메인팝업 열기
 */
export const actionOpenMainPopup = (): AppAction => {
  return action(ActionTypes.OPEN_MAIN_POPUP);
};

/**
 * @name 메인팝업 닫기
 */
export const actionCloseMainPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_MAIN_POPUP);
};
/**
 * @name 스노우플랜팝업 열기
 */
export const actionOpenSnowPlanPopup = (): AppAction => {
  return action(ActionTypes.OPEN_SNOWPLAN_POPUP);
};

/**
 * @name 스노우플랜팝업 닫기
 */
export const actionCloseSnowPlanPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_SNOWPLAN_POPUP);
};

/**
 * @name 웰컴팝업 열기
 */
export const actionOpenWelcomePopup = (): AppAction => {
  return action(ActionTypes.OPEN_WELCOME_POPUP);
};

/**
 * @name 웰컴팝업 닫기
 */
export const actionCloseWelcomePopup = (): AppAction => {
  return action(ActionTypes.CLOSE_WELCOME_POPUP);
};

/**
 * @name 웰컴팝업 초기화
 */
export const actionInitWelcomePopup = (): AppAction => {
  return action(ActionTypes.INIT_WELCOME_POPUP);
};

/**
 * @name 로딩팝업 열기
 */
export const actionOpenLoadingPopup = (): AppAction => {
  return action(ActionTypes.OPEN_LOADING_POPUP);
};

/**
 * @name 로딩팝업 닫기
 */
export const actionCloseLoadingPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_LOADING_POPUP);
};

/**
 * @name 인트로팝업 열기
 */
export const actionOpenIntroPopup = (): AppAction => {
  return action(ActionTypes.OPEN_INTRO_POPUP);
};

/**
 * @name 인트로팝업 닫기
 */
export const actionCloseIntroPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_INTRO_POPUP);
};

/**
 * @name 스캔앤고 시작 열기
 */
export const actionOpenStartScango = (): AppAction => {
  return action(ActionTypes.OPEN_START_SCANGO);
};

/**
 * @name 스캔앤고 시작 닫기
 */
export const actionCloseStartScango = (): AppAction => {
  return action(ActionTypes.CLOSE_START_SCANGO);
};

/**
 * @name 앱 기기정보 저장
 */
export const actionDeviceInfoSave = (deviceInfo: TDeviceInfo): AppAction => {
  return action(ActionTypes.APP_DEVICE_INFO, { deviceInfo });
};

/**
 * @name PUSH 목록 저장
 */
export const selectMergedPushList = (
  mergedPushList: (TAdvertisingPush | TNoticePush)[],
): AppAction => {
  return action(ActionTypes.GET_MERGED_PUSH_LIST, { mergedPushList });
};

/**
 * @name 스캔앤고 배너 정보
 */
export const actionGetScangoBanner = (
  scangoBannerList: TBanner[],
): AppAction => {
  return action(ActionTypes.GET_SCANGO_BANNER, { scangoBannerList });
};

/**
 * @name 스캔앤고 인트로 팝업 열기
 */
export const actionOpenScangoIntroPopup = (): AppAction => {
  return action(ActionTypes.OPEN_SCANGO_INTRO_POPUP);
};

/**
 * @name 스캔앤고 인트로 팝업 닫기
 */
export const actionCloseScangoIntroPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_SCANGO_INTRO_POPUP);
};

/**
 * @name 주이용점포 설정 팝업 열기
 */
export const actionOpenMartSettingPopup = (): AppAction => {
  return action(ActionTypes.OPEN_MART_SETTING_POPUP);
};

/**
 * @name 주이용점포 설정 팝업 닫기
 */
export const actionCloseMartSettingPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_MART_SETTING_POPUP);
};

/**
 * @name 점검안내(앱카드) 팝업 열기
 */
export const actionOpenInspectionAppcardPopup = (): AppAction => {
  return action(ActionTypes.OPEN_INSPECTION_APPCARD_POPUP);
};

/**
 * @name 점검안내(앱카드) 팝업 닫기
 */
export const actionCloseInspectionAppcardPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_INSPECTION_APPCARD_POPUP);
};

/**
 * @name 스플래시 닫힘 상태 체크
 */
export const actionIsHideSplash = (): AppAction => {
  return action(ActionTypes.IS_HIDE_SPLASH);
};

/**
 * @name 시스템 팝업 목록 저장
 */
export const actionSetInspectionPopupList = (
  inspectionPopupList: TSysPopup[],
): AppAction => {
  return action(ActionTypes.SET_SYSTEM_INSPECTION_POPUP_LIST, {
    inspectionPopupList,
  });
};

/**
 * @name 필수 업데이트 팝업 목록 저장
 */
export const actionSetUpdatePopupList = (
  updatePopupList: TSysPopup[],
): AppAction => {
  return action(ActionTypes.SET_SYSTEM_UPDATE_POPUP_LIST, { updatePopupList });
};

/**
 * @name MDPICK 상태 값
 */
export const actionSetMdpickYn = (mdpickYn: string): AppAction => {
  return action(ActionTypes.SET_MDPICK_YN, {
    mdpickYn,
  });
};

/**
 * @name 공유카드 팝업 열기
 */
export const actionOpenShareCardPopup = (): AppAction => {
  return action(ActionTypes.OPEN_SHARECARD_POPUP);
};

/**
 * @name 공유카드 팝업 닫기
 */
export const actionCloseShareCardPouup = (): AppAction => {
  return action(ActionTypes.CLOSE_SHARECARD_POPUP);
};
