import React, { useEffect } from 'react';
import { RootState } from 'src/store/reducers';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { history } from 'src/hooks/history';
import useSelectorEqual from 'src/hooks/useSelectorEqual';

import { E_MAIN_MENU_STATE } from 'src/redux/types/mainTypes';
import { E_POPUP_STATE } from 'src/utils/enum';
import {
  actionCloseMainMenu,
  actionCloseMartSearchPopup,
} from 'src/redux/actions/mainActions';
import {
  actionCloseAlertPopup,
  actionCloseAppCardPouup,
  actionCloseConfirmPopup,
  actionCloseScangoIntroPopup,
  actionCloseShareCardPouup,
  actionCloseSnowPlanUsePopup,
  actionCloseTermsPouup,
} from 'src/redux/actions/appActions';

import SideMenu from '../SideMenu';
import AppCard from '../AppCard';
import Terms from '../Terms';
import Portal from 'src/components/Portal';
import ConfirmPopup from 'src/components/Popup/ConfirmPopup';
import AlertPopup from 'src/components/Popup/AlertPopup';
import MartSearchPopup from 'src/containers/MartSearchPopup';
import LoadingPopup from 'src/components/Popup/LoadingPopup';
import { bridgeBarcodeClose } from 'src/utils/bridge';
import ScangoIntroPopup from 'src/components/Popup/ScangoIntroPopup';
import MartSettingPopup from 'src/components/Popup/MartSettingPopup';
import InspectionAppcardPopup from 'src/components/Popup/InspectionAppcardPopup';
import SnowPlanUsePopup from '../AppCard/components/SnowPlanUsePopup';
import ShareCard from '../ShareCard';

function ProtalControl(): React.ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();

  const [
    mainMenuState,
    isOpenMartSearchPopup,
    confirmPopupState,
    appCardPopupState,
    alertPopupState,
    termsPopupState,
    isOpenLoadingPopup,
    isOpenScangoIntroPopupState,
    isOpenMartSettingPopupState,
    isOpenInspectionAppcardPopup,
    isOpenSnowPlanUsePopup,
    shareCardPopupState,
  ] = useSelectorEqual((state: RootState) => [
    state.main.mainMenuState,
    state.main.isOpenMartSearchPopup,
    state.app.confirmPopupState,
    state.app.appCardPopupState,
    state.app.alertPopupState,
    state.app.termsPopupState,
    state.app.isOpenLoadingPopup,
    state.app.isOpenScangoIntroPopupState,
    state.app.isOpenMartSettingPopupState,
    state.app.isOpenInspectionAppcardPopup,
    state.app.isOpenSnowPlanUsePopup,
    state.app.shareCardPopupState,
  ]);

  //뒤로가기 시 팝업 닫기
  useEffect(() => {
    const listenBackEvent = (): any => {
      if (
        isOpenMartSearchPopup === E_POPUP_STATE.OPEN ||
        alertPopupState.isOpen ||
        confirmPopupState.isOpen ||
        appCardPopupState === E_POPUP_STATE.OPEN ||
        termsPopupState.isOpen ||
        isOpenScangoIntroPopupState === E_POPUP_STATE.OPEN ||
        isOpenSnowPlanUsePopup === E_POPUP_STATE.OPEN ||
        shareCardPopupState === E_POPUP_STATE.OPEN
      ) {
        dispatch(actionCloseMartSearchPopup());
        dispatch(actionCloseAlertPopup());
        dispatch(actionCloseConfirmPopup());
        dispatch(actionCloseAppCardPouup());
        dispatch(actionCloseTermsPouup());
        dispatch(actionCloseScangoIntroPopup());
        dispatch(actionCloseSnowPlanUsePopup());
        dispatch(actionCloseShareCardPouup());
        window.history.pushState(null, '', location.pathname + location.search);
        bridgeBarcodeClose();
      }

      if (mainMenuState === E_MAIN_MENU_STATE.OPEN) {
        dispatch(actionCloseMainMenu());
        window.history.pushState(null, '', location.pathname + location.search);
      }
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return () => unlistenHistoryEvent();
  }, [
    dispatch,
    location,
    alertPopupState.isOpen,
    appCardPopupState,
    confirmPopupState.isOpen,
    isOpenMartSearchPopup,
    mainMenuState,
    termsPopupState.isOpen,
    isOpenScangoIntroPopupState,
    isOpenSnowPlanUsePopup,
    shareCardPopupState,
  ]);

  return (
    <>
      {mainMenuState !== E_MAIN_MENU_STATE.NONE && (
        <Portal
          id="mainShidSheetPopup"
          isOpen={mainMenuState === E_MAIN_MENU_STATE.OPEN}
        >
          <SideMenu isOpen={mainMenuState} />
        </Portal>
      )}

      {isOpenMartSearchPopup === E_POPUP_STATE.OPEN && (
        <Portal
          id="martSearchPopup"
          isOpen={isOpenMartSearchPopup === E_POPUP_STATE.OPEN}
        >
          <MartSearchPopup isOpen={isOpenMartSearchPopup} />
        </Portal>
      )}

      {confirmPopupState.isOpen && (
        <Portal id="confirmPopup" isOpen={confirmPopupState.isOpen}>
          <ConfirmPopup confirmPopupState={confirmPopupState} />
        </Portal>
      )}

      {appCardPopupState === E_POPUP_STATE.OPEN && (
        <Portal
          id="appCardPopup"
          isOpen={appCardPopupState === E_POPUP_STATE.OPEN}
        >
          <AppCard appCardPopupState={appCardPopupState} />
        </Portal>
      )}

      {isOpenSnowPlanUsePopup === E_POPUP_STATE.OPEN && (
        <Portal
          id="snowPlanUsePopup"
          isOpen={isOpenSnowPlanUsePopup === E_POPUP_STATE.OPEN}
        >
          <SnowPlanUsePopup isOpen={isOpenSnowPlanUsePopup} />
        </Portal>
      )}

      {alertPopupState.isOpen && (
        <Portal id="alertPopup" isOpen={alertPopupState.isOpen}>
          <AlertPopup alertPopupState={alertPopupState} />
        </Portal>
      )}

      {termsPopupState.isOpen && (
        <Portal id="termsPopup" isOpen={termsPopupState.isOpen}>
          <Terms termsPopupState={termsPopupState} />
        </Portal>
      )}

      {isOpenLoadingPopup === E_POPUP_STATE.OPEN && (
        <Portal
          id="loadingPopup"
          isOpen={isOpenLoadingPopup === E_POPUP_STATE.OPEN}
        >
          <LoadingPopup />
        </Portal>
      )}

      {isOpenScangoIntroPopupState === E_POPUP_STATE.OPEN && (
        <Portal
          id="scangoIntroPopup"
          isOpen={isOpenScangoIntroPopupState === E_POPUP_STATE.OPEN}
        >
          <ScangoIntroPopup isOpen={isOpenScangoIntroPopupState} />
        </Portal>
      )}

      {isOpenMartSettingPopupState === E_POPUP_STATE.OPEN && (
        <Portal
          id="martSettingPopup"
          isOpen={isOpenMartSettingPopupState === E_POPUP_STATE.OPEN}
        >
          <MartSettingPopup isOpen={isOpenMartSettingPopupState} />
        </Portal>
      )}

      {isOpenInspectionAppcardPopup === E_POPUP_STATE.OPEN && (
        <Portal
          id="inspectionAppcardPopup"
          isOpen={isOpenInspectionAppcardPopup === E_POPUP_STATE.OPEN}
        >
          <InspectionAppcardPopup />
        </Portal>
      )}

      {shareCardPopupState === E_POPUP_STATE.OPEN && (
        <Portal
          id="shareCardPopup"
          isOpen={shareCardPopupState === E_POPUP_STATE.OPEN}
        >
          <ShareCard shareCardPopupState={shareCardPopupState} />
        </Portal>
      )}
    </>
  );
}

export default ProtalControl;
