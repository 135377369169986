import React, { useEffect, useState, useCallback, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { gaPageView } from 'src/utils/ga';
import { isAndWebView, isIOSWebView } from 'src/utils/constants';
import { storageMartgoTkn, storageMembersTkn } from 'src/utils/sso';

import ProtalControl from './components/ProtalControl';
import { TDeviceInfo } from 'src/redux/types/appTypes';
import { actionDeviceInfoSave } from 'src/redux/actions/appActions';
import { useDispatch } from 'react-redux';
const BasePageLazy = lazy(() => import('./components/BasePage'));

function App(): React.ReactElement {
  const [gaInitialized, setGAInitialized] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const getAppInfo = useCallback(() => {
    try {
      const callbackName = 'callbackGetAppInfo';
      if (isIOSWebView) {
        webkit.messageHandlers.getAppInfo.postMessage({
          callback: callbackName,
        });
      } else if (isAndWebView) {
        window.binder?.getAppInfo(callbackName);
      }

      window[callbackName] = (
        auto,
        userId,
        os,
        osVer,
        modelNm,
        appVer,
        deviceId,
      ) => {
        localStorage.setItem('AUTO_LOGIN_STATE', auto);

        //웰컴 팝업 노출 횟수 초기화
        if (auto === 'Y') {
          localStorage.setItem('welcomePopupCnt', '0');
        }

        const device_os = os === 'I' ? 'IOS' : os === 'A' ? 'ANDROID' : '';
        const deviceInfo: TDeviceInfo = {
          device_id: deviceId,
          device_os: device_os,
          model_nm: modelNm,
          app_ver: appVer,
          wdata: localStorage.getItem('wdata') || '',
          ukey: localStorage.getItem('ukey') || '',
          osVer: osVer,
        };
        dispatch(actionDeviceInfoSave(deviceInfo));
      };
    } catch (e) {
      console.log('error bridge getAppInfo');
    }
  }, [dispatch]);

  const getLoginInfo = useCallback(() => {
    try {
      const callbackName = 'callbackGetLoginInfo';
      if (isIOSWebView) {
        webkit.messageHandlers.getLoginInfo.postMessage({
          callback: callbackName,
        });
      } else if (isAndWebView) {
        window.binder?.getLoginInfo(callbackName);
      } else {
        //local 실행
        setIsSuccess(true);
      }

      window[callbackName] = (
        access_token,
        refresh_token,
        acesTkn,
        rnwTkn,
        ssoTkn,
      ) => {
        const martGoTkn = {
          access_token: access_token,
          refresh_token: refresh_token,
        };
        const membersTkn = {
          acesTkn: acesTkn,
          rnwTkn: rnwTkn,
          ssoTkn: ssoTkn,
        };
        if (access_token) storageMartgoTkn(martGoTkn); //마트GO 토큰 저장
        if (acesTkn) storageMembersTkn(membersTkn); //멤버스 토큰 저장
        setIsSuccess(true);
      };
    } catch (e) {
      console.log('error bridge getLoginInfo');
      setIsSuccess(true);
    }
  }, []);

  /**
   * @name 앱 토큰 정보 조회
   * @description 로그인에 필요한 토큰 정보 조회
   */
  useEffect(() => {
    getAppInfo();
    getLoginInfo();
  }, [getLoginInfo, getAppInfo]);

  /**
   * @name GA 설정
   */
  useEffect(() => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      setGAInitialized(true);
    }
  }, []);
  /**
   * @name GA 페이지 뷰 공통 호출
   */
  useEffect(() => {
    if (!gaInitialized) return;
    gaPageView(location.pathname, '');
  }, [gaInitialized, location.pathname]);

  /**
   * @name kakao 설정
   * Kakao.init() 또는 window.Kakao.init()을 통해 SDK 초기화 함수를 호출하면
   * Kakao Javascript SDK는 내부적으로 카카오 API와 통신할 수 있는 준비를 마치고,
   * 전역 객체인 window객체의 속성으로 Kakao객체를 할당하고
   * 이를 통해 우리의 웹 어플리케이션에서 카카오의 다양한 기능을 활용할 수 있게 된다.
   */
  useEffect(() => {
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.REACT_APP_KAKAO_KEY);
      }
    }
  }, []);

  return (
    <>
      {isSuccess && (
        <>
          <div id="background">
            <BasePageLazy />
          </div>
          <ProtalControl />
        </>
      )}
    </>
  );
}

export default App;
