import { E_POPUP_STATE } from 'src/utils/enum';
import { AppAction } from '../actions/appActions';
import ActionTypes from '../constants/appConstants';
import { TAppState } from '../types/appTypes';

export const initialState = {
  confirmPopupState: {
    isOpen: false,
    onClose: function (): void {
      return;
    },
    onConfirm: function (): void {
      return;
    },
  },
  appCardPopupState: E_POPUP_STATE.NONE,
  alertPopupState: {
    isOpen: false,
    onClose: function (): void {
      return;
    },
  },
  termsPopupState: {
    isOpen: false,
    agmtInfo: {
      agmt_desc: '',
      agmt_id: '',
      agmt_dvs: '',
      agmt_dvs_nm: '',
      agmt_type: '',
      agmt_type_nm: '',
      must_yn: '',
      agmt_nm: '',
      agmt_ver: '',
      agmt_s3_uri: '',
      agmt_text: '',
      recv_dvs: '',
      recv_dvs_nm: '',
    },
  },
  isOpenSnowPlanUsePopup: E_POPUP_STATE.NONE,
  isOpenMainPopup: E_POPUP_STATE.NONE,
  isOpenSnowPlanPopup: E_POPUP_STATE.NONE,
  isOpenWelcomePopup: E_POPUP_STATE.NONE,
  isOpenLoadingPopup: E_POPUP_STATE.NONE,
  isOpenIntroPopup: E_POPUP_STATE.NONE,
  isOpenStartScango: E_POPUP_STATE.NONE,
  isOpenScangoIntroPopupState: E_POPUP_STATE.NONE,
  isOpenMartSettingPopupState: E_POPUP_STATE.NONE,
  isOpenInspectionAppcardPopup: E_POPUP_STATE.NONE,
  deviceInfo: {
    device_id: '',
    device_os: '',
    model_nm: '',
    app_ver: '',
    wdata: '',
    ukey: '',
    osVer: '',
  },
  mergedPushList: [
    // {
    //   adyn: 'Y',
    //   body: '푸시발송테스트입니다.(내용)',
    //   date: '2021.07.07 10:35:52',
    //   image: 'https://i.ytimg.com/vi/dHmyY0iX0ww/hqdefault.jpg',
    //   convertDate: '20210707103552',
    //   link: 'http://m.coupond.lottemart.com/application/main/main.html#/v2/cpbooklist?_msg_id=0001234',
    //   rowid: '1',
    //   title: '푸시발송테스트입니다.(제목)',
    //   pushType: 'A',
    // },
    // {
    //   adyn: 'Y',
    //   body: '푸시발송테스트입니다.(내용)',
    //   date: '2021.07.07 10:35:52',
    //   image: 'https://i.ytimg.com/vi/dHmyY0iX0ww/hqdefault.jpg',
    //   convertDate: '20210707103552',
    //   link: 'http://m.coupond.lottemart.com/application/main/main.html#/v2/cpbooklist?_msg_id=0001234',
    //   rowid: '1',
    //   title: '푸시발송테스트입니다.(제목)',
    //   pushType: 'A',
    // },
    // {
    //   adyn: 'Y',
    //   body: '푸시발송테스트입니다.(스탬프)',
    //   date: '2021.03.19 13:37:32',
    //   image: '',
    //   convertDate: '20210706103552',
    //   link: 'http://m.coupond.lottemart.com/renewal/index.html#/?homeTabIndex=ALLIANCE&content_url=https://www.smlounge.co.kr/woman/article/47005?utm_source=lottemart_amp_utm_medium=display_amp_utm_campaign=mLounge_amp_push_yn=y&_msg_id=0001234',
    //   rowid: '7',
    //   title: '푸시발송테스트입니다.(스탬프)',
    //   pushType: 'A',
    // },
    // {
    //   adyn: 'Y',
    //   body: '푸시발송테스트입니다.(스탬프)',
    //   date: '2021.03.19 13:27:02',
    //   image: '',
    //   convertDate: '20210319132702',
    //   link: 'http://m.coupond.lottemart.com/renewal/index.html#/stampSI0000000003329&_msg_id=0001234',
    //   rowid: '5',
    //   title: '푸시발송테스트입니다.(스탬프)',
    //   pushType: 'A',
    // },
    // {
    //   adyn: 'Y',
    //   body: '푸시발송테스트입니다.(강제발송~)',
    //   date: '2021.03.19 10:48:47',
    //   image: '',
    //   convertDate: '20210319104847',
    //   link: 'http://m.coupond.lottemart.com/renewal/index.html?_msg_id=0001234',
    //   rowid: '2',
    //   title: '푸시발송테스트입니다.(강제발송~)',
    //   pushType: 'A',
    // },
    // {
    //   snd_serial_no: '111',
    //   send_gbn: 'B',
    //   push_title: 'N푸시발송테스트입니다.(강제발송~)',
    //   snd_content: '푸시발송테스트입니다.(강제발송~)aaaa',
    //   shcut_url: 'http://m.coupond.lottemart.com/renewal/index.html',
    //   rserv_snd_date: '2021.03.19 10:48:47',
    //   convertDate: '20210707104847',
    //   pushType: 'N',
    // },
    // {
    //   snd_serial_no: '112',
    //   send_gbn: 'B',
    //   push_title: 'N푸시발송테스트입니다.(강제발송~)',
    //   snd_content: '푸시발송테스트입니다.(강제발송~)aaaa',
    //   shcut_url: 'http://m.coupond.lottemart.com/renewal/index.html',
    //   rserv_snd_date: '2021.03.19 10:48:47',
    //   convertDate: '20210707104847',
    //   pushType: 'N',
    // },
  ],
  scangoBannerList: [],
  isHideSplash: false,
  inspectionPopupList: [],
  updatePopupList: [],
  mdpickYn: '',
  shareCardPopupState: E_POPUP_STATE.NONE,
};

function AppReducer(
  state: TAppState = initialState,
  action: AppAction,
): TAppState {
  switch (action.type) {
    case ActionTypes.OPEN_CONFIRM_POPUP:
      return {
        ...state,
        confirmPopupState: {
          isOpen: true,
          confirmMessage: action.payload.confirmPopupState.confirmMessage,
          onConfirm: action.payload.confirmPopupState.onConfirm,
          onClose: action.payload.confirmPopupState.onClose,
          cancelMessage: action.payload.confirmPopupState.cancelMessage,
          children: action.payload.confirmPopupState.children,
          bannerChildren: action.payload.confirmPopupState.bannerChildren,
        },
      };
    case ActionTypes.CLOSE_CONFIRM_POPUP:
      return {
        ...state,
        confirmPopupState: {
          isOpen: false,
          onConfirm: function (): void {
            return;
          },
          onClose: function (): void {
            return;
          },
        },
      };
    case ActionTypes.OPEN_APPCARD_POPUP:
      return {
        ...state,
        appCardPopupState: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_APPCARD_POPUP:
      return {
        ...state,
        appCardPopupState: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_ALERT_POPUP:
      return {
        ...state,
        alertPopupState: {
          isOpen: true,
          onClose: action.payload.alertPopupState.onClose,
          children: action.payload.alertPopupState.children,
        },
      };
    case ActionTypes.CLOSE_ALERT_POPUP:
      return {
        ...state,
        alertPopupState: {
          isOpen: false,
          onClose: function (): void {
            return;
          },
        },
      };
    case ActionTypes.OPEN_TERMS_POPUP:
      return {
        ...state,
        termsPopupState: {
          isOpen: true,
          agmtInfo: action.payload.termsPopupState.agmtInfo,
        },
      };
    case ActionTypes.CLOSE_TERMS_POPUP:
      return {
        ...state,
        termsPopupState: {
          isOpen: false,
          agmtInfo: {
            agmt_desc: '',
            agmt_id: '',
            agmt_dvs: '',
            agmt_dvs_nm: '',
            agmt_type: '',
            agmt_type_nm: '',
            must_yn: '',
            agmt_nm: '',
            agmt_ver: '',
            agmt_s3_uri: '',
            agmt_text: '',
            recv_dvs: '',
            recv_dvs_nm: '',
          },
        },
      };
    case ActionTypes.OPEN_SNOWPOINT_POPUP:
      return {
        ...state,
        isOpenSnowPlanUsePopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_SNOWPOINT_POPUP:
      return {
        ...state,
        isOpenSnowPlanUsePopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_MAIN_POPUP:
      return {
        ...state,
        isOpenMainPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_MAIN_POPUP:
      return {
        ...state,
        isOpenMainPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_SNOWPLAN_POPUP:
      return {
        ...state,
        isOpenSnowPlanPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_SNOWPLAN_POPUP:
      return {
        ...state,
        isOpenSnowPlanPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_WELCOME_POPUP:
      return {
        ...state,
        isOpenWelcomePopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_WELCOME_POPUP:
      return {
        ...state,
        isOpenWelcomePopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.INIT_WELCOME_POPUP:
      return {
        ...state,
        isOpenWelcomePopup: E_POPUP_STATE.NONE,
      };
    case ActionTypes.OPEN_LOADING_POPUP:
      return {
        ...state,
        isOpenLoadingPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_LOADING_POPUP:
      return {
        ...state,
        isOpenLoadingPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_INTRO_POPUP:
      return {
        ...state,
        isOpenIntroPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_INTRO_POPUP:
      return {
        ...state,
        isOpenIntroPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_START_SCANGO:
      return {
        ...state,
        isOpenStartScango: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_START_SCANGO:
      return {
        ...state,
        isOpenStartScango: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.APP_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload.deviceInfo,
      };
    case ActionTypes.GET_MERGED_PUSH_LIST:
      return {
        ...state,
        mergedPushList: action.payload.mergedPushList,
      };
    case ActionTypes.GET_SCANGO_BANNER:
      return {
        ...state,
        scangoBannerList: action.payload.scangoBannerList,
      };
    case ActionTypes.OPEN_SCANGO_INTRO_POPUP:
      return {
        ...state,
        isOpenScangoIntroPopupState: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_SCANGO_INTRO_POPUP:
      return {
        ...state,
        isOpenScangoIntroPopupState: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_MART_SETTING_POPUP:
      return {
        ...state,
        isOpenMartSettingPopupState: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_MART_SETTING_POPUP:
      return {
        ...state,
        isOpenMartSettingPopupState: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_INSPECTION_APPCARD_POPUP:
      return {
        ...state,
        isOpenInspectionAppcardPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_INSPECTION_APPCARD_POPUP:
      return {
        ...state,
        isOpenInspectionAppcardPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.IS_HIDE_SPLASH:
      return {
        ...state,
        isHideSplash: true,
      };
    case ActionTypes.SET_SYSTEM_INSPECTION_POPUP_LIST:
      return {
        ...state,
        inspenctionPopupList: action.payload.inspectionPopupList,
      };
    case ActionTypes.SET_SYSTEM_UPDATE_POPUP_LIST:
      return {
        ...state,
        updatePopupList: action.payload.updatePopupList,
      };
    case ActionTypes.SET_MDPICK_YN:
      return {
        ...state,
        mdpickYn: action.payload.mdpickYn,
      };
    case ActionTypes.OPEN_SHARECARD_POPUP:
      return {
        ...state,
        shareCardPopupState: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_SHARECARD_POPUP:
      return {
        ...state,
        shareCardPopupState: E_POPUP_STATE.CLOSE,
      };
    default:
      return state;
  }
}

export default AppReducer;
