import moment from 'moment';
import { IS_PROD, SUPER_CHECK } from './constants';
import { E_STAMP_KIND } from './enum';
import { TEventCouponRewardItem, TMainStore } from 'src/types/types';
import { TCouponListItem } from 'src/redux/types/CouponTypes';

/**
 * 매 세자리 마다 콤마를 추가한 문자열을 리턴한다.
 * @param num  number
 */
export const commafy = (num: number): string => {
  if (num) {
    const str: string[] = num.toString().split('.');
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return str.join(',');
  } else {
    return '0';
  }
};

/**
 * 날짜형식 YYYY.MM.DD
 * @param date  string
 * @param format string
 */
export const commaDate = (date: string, format?: string): string => {
  if (!date) return '';

  let _commaDate;
  if (format === 'MM.DD') {
    _commaDate = date.substring(4, 6) + '.' + date.substring(6, 8);
  } else {
    _commaDate =
      date.substring(0, 4) +
      '.' +
      date.substring(4, 6) +
      '.' +
      date.substring(6, 8);
  }
  return _commaDate;
};
/**
 * 날짜 형식 변환 YYYYMMDD
 * @param date  string 날짜 8자리
 * @param division string 공통 구분자 (, -  / ' ' 등)
 * @description 일자가 없는 경우 생략 함
 */
export const dateDivision = (date: string, division: string): string => {
  let _dateDivision = date.substring(0, 4) + division + date.substring(4, 6);
  if (date.length > 6) {
    _dateDivision += division + date.substring(6, 8);
  }
  return _dateDivision;
};
/**
 * 날짜형식 YYYY-MM-DD hh:mm:ss
 * @param date  string
 */
export const fullDate = (date: string): string =>
  `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
    6,
    8,
  )} ${date.substring(8, 10)}:${date.substring(10, 12)}`;

/**
 * 시간 형식 변환 hhmmss
 * @param date  string 시간 6자리
 * @param division string 공통 구분자 (, -  / ' ' 등)
 * @description 초 값이 없는 경우 생략 함
 */
export const timeDivision = (date: string, division: string): string => {
  let _timeDivision = date.substring(0, 2) + division + date.substring(2, 4);
  if (date.length > 4) {
    _timeDivision += division + date.substring(4, 6);
  }

  return _timeDivision;
};

/**
 * 특정 날짜의 요일 구하기
 * @param date  string YYYYMMDD
 */
export const getDay = (date: string): string => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const strDay =
    date.substring(0, 4) +
    '-' +
    date.substring(4, 6) +
    '-' +
    date.substring(6, 8);
  const day = moment(new Date(strDay)).day();
  const _getDay = week[day];

  return _getDay;
};

/**
 * 최근 N월 목록 조회
 * @param monthCnt  number 조회 할 개월 수
 */
export const selectMonthList = (monthCnt: number): moment.Moment[] => {
  const monthList: moment.Moment[] = [];
  let startDate = moment();

  for (let i = 0; i < monthCnt; i++) {
    monthList.push(startDate);
    startDate = startDate.clone().subtract(1, 'month');
  }

  return monthList;
};

/**
 * 숫자앞에 0 채우기
 * @param date  string
 */
export const fillZero = (width: number, str: string): string => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join('0') + str; //남는 길이만큼 0으로 채움
};

/**
 * @description 문자열에서 바이트수 리턴
 * @param string
 */
export const getStringByte = (str: string): number => {
  return str
    .split('')
    .map(s => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
};

/**
 * @description blob객체로 변환
 * @param {dataURL} 이미지 url
 * @return {Blob} 변환된 객체
 */
export const dataURLToBlob = dataURL => {
  const BASE64_MARKER = ';base64,';

  // base64로 인코딩 되어있지 않을 경우
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = parts[1];
    return new Blob([raw], {
      type: contentType,
    });
  }
  // base64로 인코딩 된 이진데이터일 경우
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength); //부호 없는 1byte 정수 배열을 생성 길이만 지정된 배열
  let i = 0;
  while (i < rawLength) {
    uInt8Array[i] = raw.charCodeAt(i);
    i++;
  }

  const blob = new Blob([uInt8Array], {
    type: contentType,
  });
  const file = new File([blob], 'jpg', {
    type: contentType,
    lastModified: Date.now(),
  });

  return file;
};

/**
 * 10자리 이하는 버림
 */
export const minTen = (n: number): number => Math.floor(n / 10) * 10;

/**
 * Parse data
 * @param date  string
 */
export const parseStringHyphen = (str: string): string => {
  const _parseStringHyphen = str.replace(/-/gi, '');
  return _parseStringHyphen;
};

/**
 * 지정한 길이 만큼 왼쪽부터 특정문자로 채워준다.
 * @param str
 * @param len
 * @param padStr
 */
export const lpad = (str: string, len: number, padStr: string): string => {
  padStr = padStr === '' ? '0' : padStr;
  while (str.length < len) str = padStr + str;
  return str;
};

/**
 * d-day 남은날짜 계산
 * @param {string} //마감날짜
 */
export const dDay = (date: string): string => {
  //마감날짜
  const yearEnd = date.substr(0, 4);
  const monthEnd = date.substr(4, 2);
  const dayEnd = date.substr(6, 2);
  const endDate = new Date(monthEnd + '/' + dayEnd + '/' + yearEnd);
  //현재날짜
  const currDate = new Date();
  //Dday 남은날짜
  const dday = (currDate.getTime() - endDate.getTime()) / 86400000;
  return dday.toString();
};

/**
 * 일정 시간(milliseconds) 이후 콜백(callback) 호출
 * @callback
 * @milliseconds
 */
export const throttle = (
  callback: () => void,
  milliseconds: number,
): (() => void) => {
  let throttleCheck;
  return function () {
    if (!throttleCheck) {
      throttleCheck = setTimeout(() => {
        callback();
        throttleCheck = false;
      }, milliseconds);
    }
  };
};

/**
 * 앱 푸시 링크에서 seq_no 값 추출
 * @param link 링크 url
 * @returns seq_no 푸시ID
 */
export const getAppPushSeqNo = (link: string): string => {
  let seq_no;

  const search = link.split('?')[1];
  if (search) {
    search.split('&').map(params => {
      const key = params.split('=')[0];
      const val = params.split('=')[1];

      if (key === 'seq_no' && val !== 'null') {
        seq_no = val;
      }
    });
  }

  return seq_no;
};

/**
 * 목록 toggle(접기/펼치기)
 * @description 이벤트 내역보기 클릭 시 사용
 */
export const onFoldItem = (foldRef, foldStore, setFoldStore) => {
  if (!foldRef || !foldRef.current) {
    return;
  }

  const style = foldRef.current.style;

  if (foldStore) {
    style.maxHeight = '0';
  } else if (!foldStore) {
    style.maxHeight = `${foldRef.current.scrollHeight}px`;
  }
  setFoldStore(!foldStore);
};

/**
 * 콘솔 메세지 출력
 * @description 운영에 미노출
 */
export const consoleLog = (msg: string) => {
  if (!IS_PROD) console.log(msg);
};

/**
 * 스캔앤고 영수증 결제내역 노출 문구 예외처리
 */
export const getScangoReceiptPayStrList = (pay: {
  text1: string;
  text2: string;
}): string[] => {
  let cardNo: string[] = [];
  const text1 = pay.text1.replace(':', ''); //승인번호 예외처리
  if (text1.indexOf(' ') > -1) {
    //카드매출 예외처리
    const _temp = text1.split(' ');
    if (_temp.length > 2) {
      cardNo = [_temp[0], _temp[1] + ' ' + _temp[2]];
    } else {
      cardNo = _temp;
    }
  } else {
    cardNo = [pay.text1, pay.text2];
  }
  return cardNo;
};

/**
 * 스탬프 이벤트 유형 체크
 * @param 스탬프 유형
 * @returns 스탬프 유형 구분 값 (PRODUCT:상품구매수량/금액, CATEGORY:카테고리수량/금액, HISTORY:구매이력)
 */
export const checkEventStampDvs = (stamp_dvs: string): string => {
  if (stamp_dvs === E_STAMP_KIND.PRODUCT || stamp_dvs === E_STAMP_KIND.PRICE) {
    return 'PRODUCT';
  } else if (
    stamp_dvs === E_STAMP_KIND.CAT_PRODUCT ||
    stamp_dvs === E_STAMP_KIND.CAT_PRICE
  ) {
    return 'CATEGORY';
  } else if (stamp_dvs === E_STAMP_KIND.HISTORY) {
    return 'HISTORY';
  } else {
    return '';
  }
};

/**
 * 등급 class 구분 값 추출
 * @param super_grade 슈퍼등급코드
 * @param super_grade_nm 슈퍼등급명칭
 * @param str_cd 주이용점포 코드
 * @param member_grade_nm 마트등급명칭
 * @returns 등급 class 구분값 (마트: MVG, VIP, GOLD, ACE / 슈퍼: S_ING, S_MVG, S_VIP, S_GOLD, S_ACE)
 * @description 슈퍼등급코드 (200:등급산정중, 201:MVG, 202:VIP, 203:GOLD, 204:ACE)
 */
export const getSuperGradeName = (
  super_grade: string,
  super_grade_nm: string,
  str_cd: string,
  member_grade_nm: string,
): string => {
  if (!(super_grade && str_cd)) {
    return '';
  }

  // 주이용점포 체크
  if (SUPER_CHECK(str_cd) && super_grade === '200') {
    // 슈퍼인 경우 등급산정중
    return 'S_ING';
  } else if (SUPER_CHECK(str_cd)) {
    // 슈퍼인 경우 슈퍼등급
    return 'S_' + super_grade_nm;
  } else {
    // 전점인 경우 마트등급
    return member_grade_nm;
  }
};

/**
 * 전단 랜딩 시 점포타입 체크
 * @param mainStoreState 주이용점포 정보
 * @returns redirect 정보
 */
type TleafletLocation = {
  pathname: string;
  search: string;
};
export const getLeafletType = (
  mainStoreState: TMainStore,
): TleafletLocation => {
  // 주이용점포 체크
  if (SUPER_CHECK(mainStoreState.str_cd)) {
    // 슈퍼인 경우 전단웹뷰
    return { pathname: '', search: '' };
  } else if (mainStoreState.main_str_type === '3') {
    //  MAXX일 경우 전단 소식으로 이동
    return { pathname: '/leaflet/news', search: 'leafletWv_yn=Y' };
  } else {
    // HYPER 또는 ZETTA일 경우 전단으로 이동
    return { pathname: '/leaflet', search: 'leafletWv_yn=Y' };
  }
};

/**
 * PMR 쿠폰 체크
 * @param couponInfo 쿠폰 정보
 * @return boolean;
 */
export const getIsPmrCoupon = (
  couponInfo: TCouponListItem | TEventCouponRewardItem,
): boolean => {
  if (couponInfo.cpn_kind === '4' && couponInfo.cpn_type === '7') {
    return true;
  } else {
    return false;
  }
};

/**
 *
 *
 */

export const checkDirectUrl = (): boolean => {
  const directUrl = sessionStorage.getItem('historyback_directUrl');
  if (directUrl && directUrl.indexOf(location.pathname) > -1) {
    //초기화
    sessionStorage.setItem('historyback_directUrl', '');
    //외부진입 시 저장된 url과 같으면 history 없음. 메인으로 이동
    return true;
  } else {
    //history.back
    return false;
  }
};
