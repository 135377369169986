import ReactGA from 'react-ga4';
import { PAGE_PATH_LIST } from 'src/containers/App/constants';
import { isAndWebView, isIOSWebView } from './constants';
import { IS_PROD } from './constants';

/**
 * @description GA 태깅 - 페이지뷰
 * @param pathname location.pathname
 * @param vId 페이지 ID
 * @param vName 페이지명 (각 페이지에서 호출 시 필수)
 * @description 브릿지로 전송해서 firebase에도 데이터 수집
 */
export const gaPageView = (
  pathname: string,
  vId: string,
  vName?: string,
): void => {
  const currentPathList = PAGE_PATH_LIST.filter(
    pagePathData => pagePathData.pathname === window.location.pathname,
  );
  const pageName = vName
    ? vName
    : currentPathList.length > 0 && currentPathList[0].pageName
    ? currentPathList[0].pageName
    : '';
  const _vId = vId ? `[${vId}]` : '';

  // pageName 없는 페이지는 각 페이지에 직접 호출 (상세 id 및 정보 수집 필요 시)
  if (pageName) {
    ReactGA.send({
      hitType: 'pageview',
      title: `<APP> ${pageName} ${_vId}`,
      page: pathname,
    });

    if (IS_PROD) {
      try {
        if (isIOSWebView) {
          webkit.messageHandlers.sendFaView.postMessage({
            vId: String(vId),
            vName: pageName,
          });
        } else if (isAndWebView) {
          window.binder?.sendFaView(String(vId), pageName);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};

/**
 * @description GA 태깅 - 이벤트
 * @param eType 버튼 이름
 * @param eId 이벤트 ID
 * @param eName 이벤트 명
 * @param vName 페이지 명
 */
export const gaEvent = (
  eType: string,
  eId: string,
  eName: string,
  vName?: string,
): void => {
  const currentPathList = PAGE_PATH_LIST.filter(
    pagePathData => pagePathData.pathname === window.location.pathname,
  );

  const pageName = vName
    ? vName
    : currentPathList.length > 0 && currentPathList[0].pageName
    ? currentPathList[0].pageName
    : window.location.pathname + window.location.search;

  const _eName = eName ? `[${eName}]` : '';
  const _eId = eId ? `[${eId}]` : '';

  ReactGA.event({
    action: `${eType} 클릭${_eName}`,
    category: `<APP> ${pageName} ${_eId}`,
    label: eId,
  });

  if (IS_PROD) {
    try {
      if (isIOSWebView) {
        webkit.messageHandlers.sendFaEvent.postMessage({
          eId: String(eId),
          eName: eName,
          eCate: pageName,
          eType: eType,
        });
      } else if (isAndWebView) {
        window.binder?.sendFaEvent(String(eId), eName, pageName, eType);
      }
    } catch (e) {
      console.log(e);
    }
  }
};
