import React from 'react';
import { StyledPopupMessage, StyledPopupSnowMessage } from '../styles';

import { rem } from 'src/styles/function';
import { commafy } from 'src/utils/utility';
import { useTranslation } from 'react-i18next';

/**
 * @name 스캔앤고 팝업 메시지
 * @param strNm 점포명
 */

export const PopupMessageScanGo = (props: {
  strNm: string;
}): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        현재 설정 점포는 <strong>{props.strNm}</strong>이에요.
        <br />
        방문 점포와 설정 점포가 다를 경우 SCAN&GO 이용이 불가해요. SCAN&GO를
        시작할까요?
      </p>
      <br />
      <span className="sub_txt">
        SCAN&GO는 카드 결제만 가능해요.
        <br />
        SCAN&GO는 롯데마트&MAXX카드 혜택 적용이 불가해요.
      </span>
    </StyledPopupMessage>
  );
};

export const PopupMessageRemoveBag = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        고객님
        <br />
        매장 변경 시 장바구니가 초기화됩니다.
        <br />
        변경하시겠습니까?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageSuper = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>슈퍼 정보를 안내합니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageWithdrawMartgo = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>정말 롯데마트GO앱 서비스를 탈퇴하시겠습니까?</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageWithdrawLpoint = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        L.POINT서비스를 탈퇴 시<br />
        48시간동안 롯데마트GO앱 재가입이 제한되며 이벤트 혜택 사용도 제한됩니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageScangoAddBasket = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        고객님
        <br />
        결제 취소한 상품이
        <br />
        장바구니에 다시 담겼습니다.
        <br />
        다시 결제해주세요.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageLpointLoginId = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <StyledPopupMessage>
      <p>{t('아이디를 입력하세요.')}</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageLpointLoginPw = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <StyledPopupMessage>
      <p>{t('비밀번호를 입력하세요.')}</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageKidWithdrawal = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        우리아이M혜택을 탈퇴하시겠습니까? 탈퇴시 1개월간 재가입할 수 없습니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageKidJoin = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>우리아이M혜택에 가입완료 되었습니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageQnaRegister = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        1:1건의가 등록되었습니다. 등록하신 건의에 대한 답변은
        전체메뉴&gt;도움&gt;건의내역 확인에서 확인 가능합니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageQnaCancel = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        1:1 건의를 취소하시겠습니까?
        <br />
        작성중인 글은 삭제됩니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageQnaValidBranch = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>건의 매장을 선택해주세요.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageQnavalidContent = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>건의 내용을 입력해주세요.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageLottemartMall = (props: {
  branchNm: string;
}): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        고객님,
        <br />
        현재 <strong>{props.branchNm}</strong> 상품을 보고 계십니다.
        <br />
        롯데마트 제타에서 배송되는 점에 따라 재고 및 상품정보가 다소 차이가 있을
        수 있습니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageStartScango = (props: {
  str: string;
}): React.ReactElement => {
  return (
    <StyledPopupMessage>
      {props.str.split('<br/>').map((str, i) => (
        <p key={i}>{str}</p>
      ))}
    </StyledPopupMessage>
  );
};

export const PopupStopUseBarcode = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        LPOINT 전환 신청 처리 중입니다.
        <br />
        이용이 제한 됩니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessagePushMktCheck = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <StyledPopupMessage>
      <p>
        {t(
          '광고성 정보(PUSH) 수신 동의를 위해 마케팅 목적 정보 이용 동의가 필요합니다.',
        )}
        <br />
        {t('마케팅 목적 정보 이용동의를 진행하시겠습니까?')}
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageWrongEmpAuth = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>올바르지 않은 입력입니다. 다시 입력해주시기 바랍니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageWrongEmpNo = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>사원번호를 입력해주세요.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageWrongEmpId = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>마이다스 아이디를 입력해주세요.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageCompleteEmpAuth = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>임직원 인증이 완료되었습니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageWrongEmpAuthNo = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>인증번호 오류입니다. 다시 시도해주시기 바랍니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageTimeOverEmpAuth = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>인증시간이 초과되었습니다. 다시 시도해주시기 바랍니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageHistoryDifferentBranch = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        주 이용 점포가 달라 장바구니에 담을 수 없습니다.
        <br />주 이용 점포를 확인해주세요.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageAuthComplete = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        직원 인증이 완료되었습니다.
        <br />
        감사합니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageReceiptAlert = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        스마트영수증 준비 중 입니다.
        <br />
        2~3분 후 다시 확인을
        <br />
        부탁드립니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessagePaymentFail = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>결제요청 중 에러가 발생하였습니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageCIAlert = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        친환경 캠페인에 동참해 주셔서 감사합니다.
        <br />
        종이 영수증 필요시, 계산대에 요청해주세요.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageDeleteAlert = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>정말 삭제하시겠습니까?</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageMdPickCouponAllDownLoad = () => {
  return (
    <StyledPopupMessage>
      <p>
        모든 쿠폰 발급이 완료되었습니다.
        <br />
        MY혜택으로 이동하여
        <br />
        쿠폰을 확인하시겠습니까?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageMdPickCouponDownLoad = () => {
  return (
    <StyledPopupMessage>
      <p>
        쿠폰 발급이 완료되었습니다.
        <br />
        MY혜택으로 이동하여
        <br />
        쿠폰을 확인하시겠습니까?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageSettingAlert = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        권한설정이 되어 있지 않습니다.
        <br />
        설정페이지로 이동 하시겠습니까?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageScangoIsBag = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        장바구니에 구매 중인 상품이 있어요.
        <br />
        기존 장바구니를 불러올까요?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageScangoIsBagError = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        장바구니를 불러 올 수 없어요.
        <br />
        잠시후 다시 시도해주세요.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageExcPoint = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        적립완료! 포인트 탭에서 적립된 내역을 확인할 수 있어요.
        <br />
        포인트 탭으로 이동할까요?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageExcSnowPoint = (props: {
  amt: number;
}): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        스노우플랜 {props.amt}p 적립완료! 포인트 탭에서 적립된 내역을 확인할 수
        있어요.
        <br />
        포인트 탭으로 이동할까요?
      </p>
    </StyledPopupMessage>
  );
};

export const PopupAgreeExchangeLpoint = (props: {
  refine: number;
  expect: number;
}): React.ReactElement => {
  return (
    <StyledPopupSnowMessage>
      <p>
        <strong style={{ color: '#009bfa' }}>
          {commafy(props.refine)}스노우플랜
        </strong>
        을{' '}
        <strong style={{ color: '#009bfa' }}>
          {commafy(props.expect)}L.POINT
        </strong>
        로 전환을 시작합니다.
        <br />
        <br />
        <strong>
          {' '}
          처리결과는 이용내역&gt;사용/소멸 내역에서 확인하실 수 있습니다.
          전환실패 시에는 다시 신청하시면 됩니다.{' '}
        </strong>
        <br />
        <br />
        전환된 L.POINT는 엘포인트앱에서 확인하시고 사용하시면 됩니다. 전환 신청
        후{' '}
        <strong>
          스노우플랜 사용하기는 최대 1시간 동안 제한됩니다. 전환 작업이 시작되면
          원복되거나 취소할 수 없습니다. 아울러 전환된 엘포인트는 스노우플랜으로
          다시 전환될 수 없습니다.
        </strong>
        <br />
        <br />위 사항에 동의하시고 진행하신다면 &#39; 동의&#39; 를 원치않으시면
        &#39; 취소&#39; 를 눌러주세요.
      </p>
    </StyledPopupSnowMessage>
  );
};

export const PopupMessageFailPayment = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        결제가 실패하였습니다.
        <br />
        장바구니로 이동합니다.
      </p>
    </StyledPopupMessage>
  );
};

export const PopupSnowPlanNotice = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p
        style={{
          marginBottom: `${rem(10)}`,
          fontFamily: `TheJamsil-4Medium`,
        }}
      >
        스노우플랜 멤버십 대상 안내
      </p>
      <p>
        LPOINT 개인정보수집 &middot; 이용 &middot; 제 3자 제공에 대한 필수
        &middot; 선택동의 고객 중 최근 1년 내 LPOINT 적립 이력이 있는
        롯데마트GO회원
      </p>
    </StyledPopupMessage>
  );
};

export const PopupMessageSnowPlanReward = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        스노우플랜 10P 적립완료!
        <br />
        &#91; 포인트 &gt; 스노우플랜 &gt; 적립상세보기 &#93;에서 확인
      </p>
    </StyledPopupMessage>
  );
};

// 카카오뱅크 이벤트 팝업 - 쿠폰 발급 완료
export const PopupMessageCouponReward = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        추가 제휴 쿠폰을 발급을 완료했습니다.
        <br />
        My혜택으로 이동해서 확인하시겠습니까?
        <br />
        <br />
        쿠폰은 주이용점포가 롯데마트 또는 ZETTAPLEX인 경우에만 확인 가능합니다.
      </p>
    </StyledPopupMessage>
  );
};

// 카카오뱅크 이벤트 팝업 - 잘못된 쿠폰 코드
export const PopupMessageWrongCouponNo = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>쿠폰 코드를 다시 확인해주시기 바랍니다.</p>
    </StyledPopupMessage>
  );
};

// 카카오뱅크 이벤트 팝업 - 이미 발급한 쿠폰
export const PopupMessageCompletedCouponNo = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>
        이미 쿠폰을 발급을 받으셨습니다.
        <br />
        My혜택으로 이동해서 확인하시겠습니까?
        <br />
        <br />
        쿠폰은 주이용점포가 롯데마트 또는 ZETTAPLEX인 경우에만 확인 가능합니다.
      </p>
    </StyledPopupMessage>
  );
};

// 카카오뱅크 이벤트 팝업 - 이벤트 기간 x
export const PopupMessageCompletedEvent = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>이벤트 기간이 아닙니다.</p>
    </StyledPopupMessage>
  );
};

export const PopupMessageDeleteCarNumber = (): React.ReactElement => {
  return (
    <StyledPopupMessage>
      <p>내 차량 번호를 삭제하시겠습니까?</p>
    </StyledPopupMessage>
  );
};
