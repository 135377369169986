import moment from 'moment';
import { TMainStore } from 'src/types/types';
import { commfyHoliday } from 'src/utils/branch';
import { getDay } from 'src/utils/utility';
import { bridgeOpenAppSchemeStore } from './bridge';
import { TDeviceInfo } from 'src/redux/types/appTypes';
import {
  SCANGO_UPDATE_VER_GUIDE,
  isAndWebView,
  isIOSWebView,
} from './constants';

type TCheckScango = {
  message: string;
  openYn: boolean;
};

/**
 * @name 점포 스캔앤고 가능 체크
 * @param mainStore 주이용점포 정보
 */
export const getCheckScangoPayment = (mainStore: TMainStore): TCheckScango => {
  let checkScango: TCheckScango;

  const {
    str_stt,
    str_nm,
    start_hour,
    start_min,
    end_hour,
    end_min,
    holi_dt1,
    holi_dt2,
    holi_dt3,
  } = mainStore;

  // 휴무일 여부
  const holidayYn = [holi_dt1, holi_dt2, holi_dt3].includes(
    moment().format('MM월DD일') +
      '(' +
      getDay(moment().format('YYYYMMDD')) +
      ')',
  );
  // 영업가능 시간 여부
  const nowHHmm = moment().format('HHmm'); // 현재시간
  const startHHmm = moment(`${start_hour}${start_min}`, 'HHmm').format('HHmm'); // 시작시간
  const endHHmm = moment(`${end_hour}${end_min}`, 'HHmm').format('HHmm'); // 종료시간
  const openYn = nowHHmm >= startHHmm && nowHHmm <= endHHmm;

  if (holidayYn) {
    checkScango = {
      message: `[${str_nm}]의 휴무일 입니다.
      <br/>
      ${commfyHoliday(holi_dt1, holi_dt2, holi_dt3)}`,
      openYn: false,
    };
  } else if (!openYn) {
    checkScango = {
      message: `${str_nm}의 SCAN&GO 운영시간은
      <br/>
      ${start_hour}:${start_min} ~ ${end_hour}:${end_min} 입니다.`,
      openYn: false,
    };
  } else if (str_stt === '2') {
    checkScango = {
      message: `[${str_nm}] 긴급휴점입니다.`,
      openYn: false,
    };
  } else if (str_stt === '3') {
    checkScango = {
      message: 'SCAN&GO 임시점검 중입니다.',
      openYn: false,
    };
  } else {
    if (str_stt === '1') {
      checkScango = {
        message: '',
        openYn: true,
      };
    } else {
      // 모든 상황이 아닐 경우 예외처리
      checkScango = {
        message: '일시적으로 SCAN&GO를 사용할 수 없습니다.',
        openYn: false,
      };
    }
  }

  return checkScango;
};

/**
 * @name 스캔앤고 앱 버전 체크 후  앱 업데이트 alert
 */

export const isScangoAppUpdate = (deviceInfo: TDeviceInfo): boolean => {
  if (isIOSWebView) {
    if (deviceInfo.app_ver < SCANGO_UPDATE_VER_GUIDE.scango_appVer_ios) {
      const anwser = confirm(SCANGO_UPDATE_VER_GUIDE.scango_appVer_msg);
      // 스토어로 이동
      if (anwser) bridgeOpenAppSchemeStore();
      return true;
    } else if (deviceInfo.osVer < SCANGO_UPDATE_VER_GUIDE.scango_osVer_ios) {
      // iOS 15.4버전 체크
      alert(SCANGO_UPDATE_VER_GUIDE.scango_osVer_msg);
      return false;
    } else return false;
  } else if (isAndWebView) {
    if (deviceInfo.app_ver < SCANGO_UPDATE_VER_GUIDE.scango_appVer_aos) {
      const anwser = confirm(SCANGO_UPDATE_VER_GUIDE.scango_appVer_msg);
      // 스토어로 이동
      if (anwser) bridgeOpenAppSchemeStore();
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
