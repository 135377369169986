import api from 'src/utils/api';
import { E_API_SERVICEID } from 'src/utils/enum';

/**
 * @name 임직원 인증 약관동의 팝업 노출여부 조회
 * @param agmt_type 약관 구분
 * @description 24.09.06 미동의 사용자 데이터정리 및 하드코딩 메인 팝업 삭제
 */
export const apiEmpAgmtSelect = async params => {
  const response = await api.post(
    '/fo/assistant/emp/cert/select',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 임직원 인증 약관동의
 * @param agmt_type 약관 구분
 * @param agmt_id 약관 ID
 * @param recv_dvs 약관동의여부 0:미동의, 1:동의
 */

export const apiEmpAgmtUpdate = async params => {
  const response = await api.post(
    '/fo/assistant/emp/cert/agmt/update',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 명절 점포 휴무 조회
 * @param custom_id 이벤트 ID
 * @param str_cd 점포코드
 * @returns 0: 이벤트기간아님, 1: 정상영업, 2: 휴무
 */
export const apiGetHolidayCheck = async params => {
  const response = await api.post(
    '/fo/assistant/str/holiday/check',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 명절 점포 휴무 조회 (다중날짜)
 * @param custom_type 커스텀 타입
 * @param str_cd 점포코드
 * @returns 1: 정상영업, 2:휴무
 */
export const apiGetHolidaysCheck = async params => {
  const response = await api.post(
    '/fo/assistant/str/holiday/check/list',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 풀팝업 정보 조회
 */
export const apiGetFullPopupInfo = async () => {
  const response = await api.post(
    '/fo/assistant/popup/select',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 풀팝업 로깅
 */
export const apiFullPopupLogging = async params => {
  const response = await api.post(
    '/fo/assistant/popup/logging',
    { ...params },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 오카도 오픈 정보 구분값
 */
export const apiOkadoOpenInfo = async params => {
  const response = await api.post(
    '/fo/assistant/okado/info',
    { ...params },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 외부진입 로깅
 * @param hfrom from
 * @param rst1...rst9 그 외 로깅정보
 * */
export const apiSetLogging = async params => {
  const response = await api.post(
    '/fo/logging/outurl',
    { hfrom: 'APP', ...params },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 창립기념일 땡큐절 선착순 하드코딩 조회
 */
export const apiThankyouFcfsEvent = async params => {
  const response = await api.post(
    '/fo/assistant/tqevent/getFcfsEvent',
    { ...params },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.ASSISTANT },
    },
  );

  const data = await response.data;

  return data;
};
