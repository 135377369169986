import { IMAGE_ROOT } from './constants';

/**
 * KAKAO Feed
 * shareLeafletUrl: 공유 전단 url 링크
 * shareEventUrl: 이벤트 보러가기 url 링크
 * share_id: 공유하기 아이디
 * share_key: 암호화 한 아이디
 * share_next_no: 공휴하기 다음 횟수
 */
export const shareKakaoEventFeed = (
  shareLeafletUrl,
  shareEventUrl,
  share_id,
  share_key,
  share_next_no,
): boolean => {
  window.Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: '롯데마트 롯데슈퍼 전단',
      description: '이번주 전단 확인하고 쇼핑하자!',
      imageUrl: `${IMAGE_ROOT}/event/250326_kakaoShareBanner.jpg`,
      imageWidth: 800,
      imageHeight: 400,
      link: {
        mobileWebUrl: shareEventUrl,
        webUrl: shareEventUrl,
      },
    },
    buttons: [
      {
        title: '전단 보기',
        link: {
          mobileWebUrl: shareLeafletUrl,
          webUrl: shareLeafletUrl,
        },
      },
      {
        title: '이벤트 보기',
        link: {
          mobileWebUrl: shareEventUrl,
          webUrl: shareEventUrl,
        },
      },
    ],
    serverCallbackArgs: {
      share_id: share_id,
      share_key: share_key,
      share_next_no: share_next_no,
    },
  });

  return true;
};

export const shareKakaoFeed = (shareUrl: string) => {
  window.Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: '이벤트 참여하기',
      description: '롯데마트GO 앱에서는 혜택이 와르르!',
      imageUrl: `${IMAGE_ROOT}/event/250326_eventShareBanner.jpg`,
      imageWidth: 800,
      imageHeight: 400,
      link: {
        mobileWebUrl: shareUrl,
        webUrl: shareUrl,
      },
    },
    buttons: [
      {
        title: '이벤트 보기',
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
    ],
  });
};
