import { thejamsilFont } from 'src/styles/font';
import { rem } from 'src/styles/function';
import styled from 'styled-components';

/**
 * @name 앱카드 스타일 컴포넌트
 */
export const StyledAppCard = styled.div`
  position: relative;
  .close_btn {
    position: absolute;
    width: ${rem(40)};
    height: ${rem(40)};
    top: ${rem(-60)};
    left: 45%;
  }
  .card_header {
    height: ${rem(30)};
    padding-top: ${rem(20)};
    div {
      margin: auto;
      width: ${rem(110)};
      height: ${rem(5)};
      border-radius: ${rem(10)};
      background-color: #666666;
    }
  }
  .card_box {
    box-sizing: border-box;
    margin: auto auto ${rem(47)};
    padding: ${rem(30)} ${rem(20)} ${rem(20)};
    width: 90%;
    border-radius: ${rem(10)};
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
    .point {
      color: #0071ce;
      ${thejamsilFont('medium')}
      margin-bottom: ${rem(26.5)};
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: ${rem(16)};
        margin: auto 0;
      }
      p {
        font-size: ${rem(14)};
        span {
          font-size: ${rem(24)};
          padding-right: ${rem(4)};
        }
      }
    }
    .barcode {
      width: 100%;
      height: ${rem(100)};
      margin: auto;
      svg {
        width: 100%;
      }
    }
  }
  .card_list {
    margin: 0 ${rem(20)};
    li {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: ${rem(20)};
      div {
        margin: auto 0 auto;
      }
    }
    .blue {
      ${thejamsilFont('medium')}
      font-size: ${rem(16)};
      color: #0071ce;
    }
    .right {
      display: flex;
      justify-content: space-around;
      .snow_point {
        ${thejamsilFont('medium')}
        margin: auto ${rem(10)} auto auto;
        padding-right: ${rem(10)};
        font-size: ${rem(14)};
        color: #0071ce;
        strong {
          font-size: ${rem(24)};
        }
      }
      button {
        ${thejamsilFont('regular')}
        font-size: ${rem(14)};
        width: ${rem(90)};
        height: ${rem(40)};
      }
    }
  }
`;

export const StyledSnowPlanUsePopup = styled.section`
  z-index: 300;
  overflow-y: auto;
  position: fixed;
  height: 100%;
  top: 0;
  width: 90%;
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 0 ${rem(20)} 0 ${rem(20)};
  .pop_header {
    margin-top: ${rem(27)};
    display: flex;
    jusetify-contents: space-between;
    .title {
      ${thejamsilFont('regular')};
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    }
    .close {
      margin-left: auto;
      button {
        align-itmes: center;
        display: flex;
        margin-left: auto;
        i {
          width: ${rem(30)};
          height: ${rem(30)};
        }
      }
    }
  }
  .time {
    align-items: center;
    margin-top: ${rem(120)};
    margin-bottom: ${rem(24)};
    display: flex;
    justify-content: center;
    i {
      width: ${rem(24)};
      height: ${rem(24)};
    }
    .dec {
      margin-left: ${rem(5)};
      display: flex;
      justify-content: center;
      align-items: center;
      ${thejamsilFont('light')};
      font-size: ${rem(16)};
      line-height: ${rem(24)};
      .hightlight {
        margin-left: ${rem(5)};
        color: ${({ theme }) => theme.colors.red};
        ${thejamsilFont('medium')};
      }
    }
  }
  .card_box {
    border-radius: ${rem(10)};
    box-sizing: border-box;
    padding: ${rem(30)} ${rem(20)};
    background: ${({ theme }) => theme.colors.white};
    position: relative;
    box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: ${rem(30)};
    .point_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${rem(26)};
      .title {
        ${thejamsilFont('medium')};
        font-size: ${rem(16)};
        color: ${({ theme }) => theme.colors.blue};
      }
      .point {
        margin-left: auto;
        color: ${({ theme }) => theme.colors.blue};
        font-size: ${rem(24)};
        ${thejamsilFont('medium')};
      }
      .p {
        color: ${({ theme }) => theme.colors.blue};
        font-size: ${rem(14)};
        ${thejamsilFont('medium')};
      }
    }
    .barcode {
      width: 100%;
      height: ${rem(107)};
      margin: auto;
      svg {
        width: 100%;
      }
    }
    .btn_box {
      position: absolute;
      left: 0;
      top: 35%;
      width: 100%;
      height: 50%;
      background: rgba(255, 255, 255, 0.85);
      .btn_refresh {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
        i {
          width: ${rem(50)};
          height: ${rem(50)};
          display: flex;
          margin-top: ${rem(45)};
          object-fit: contain;
        }
      }
    }
  }
  .notice {
    ${thejamsilFont('medium')}
    margin-bottom: ${rem(30)};
    h2 {
      font-size: ${rem(14)};
      margin-bottom: ${rem(20)};
      i {
        display: inline-block;
        width: ${rem(17)};
        height: ${rem(17)};
        vertical-align: bottom;
        padding-right: ${rem(5)};
      }
    }
    ul {
      :last-child {
        margin-bottom: 0;
      }
      li {
        position: relative;
        ${thejamsilFont('medium')};
        font-size: ${rem(12)};
        margin-bottom: ${rem(20)};
        line-height: ${rem(18)};
        padding-left: ${rem(15)};
        &::before {
          position: absolute;
          content: '-';
          left: 0;
          top: 0;
        }
        strong {
          color: ${({ theme }) => theme.colors.blue};
          text-decoration: underline;
        }
      }
    }
  }
  p {
    ${thejamsilFont('light')};
    font-size: ${rem(12)};
    line-height: ${rem(20)};
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;
