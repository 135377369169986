import React from 'react';
import { useDispatch } from 'react-redux';
import Dim from 'src/components/Dim';
import { SlideUpPopupWrapper } from 'src/components/Popup/components/SlideUpPopup';
import { actionCloseShareCardPouup } from 'src/redux/actions/appActions';
import { E_POPUP_STATE } from 'src/utils/enum';
import { StyledShareCard } from '../styles';
import {
  IcoAppCardClose,
  IcoCopyUrl,
  IcoShareKakao,
} from 'src/components/Icon';
import { shareKakaoFeed } from 'src/utils/kakao';
import { bridgeSetToastPopup } from 'src/utils/bridge';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useLocation, useSearchParams } from 'react-router-dom';
import { gaEvent } from 'src/utils/ga';

type Props = {
  shareCardPopupState: E_POPUP_STATE;
};

function ShareCard(props: Props): React.ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('event_id');

  //공유 링크 : https://front.lottemartgo.com/system/launcher?_route=/event/landing?event_id={}&hfrom=APP&rst1={}&rst2={}
  const shareKakaoUrl = `${process.env.REACT_APP_BASE_FRONT_ORIGIN}/system/launcher?_route=${location.pathname}?event_id=${eventId}&hfrom=APP&rst1=S&rst2=KAKAO`;
  const copyUrl = `${process.env.REACT_APP_BASE_FRONT_ORIGIN}/system/launcher?_route=${location.pathname}?event_id=${eventId}&hfrom=APP&rst1=S&rst2=URL`;

  const browser = navigator.userAgent.toLowerCase();

  // 공유카드 닫기
  const onCloseShareCard = () => {
    dispatch(actionCloseShareCardPouup());
  };

  const handleClickShareKaKao = () => {
    if (!eventId) return;

    gaEvent('이벤트 공유하기(카카오톡)', eventId, '', '이벤트 공유버튼');
    shareKakaoFeed(shareKakaoUrl);
  };

  const handleClickcopyUrl = () => {
    if (!eventId) return;

    gaEvent('이벤트 공유하기(URL 복사)', eventId, '', '이벤트 공유버튼');
    try {
      // 안드로이드 13버전 기본 문구 중복 예외처리
      if (parseFloat(browser.substring(browser.indexOf('android') + 8)) >= 13) {
        return;
      } else {
        bridgeSetToastPopup('클립보드에 복사되었습니다.');
      }
    } catch (error) {
      alert('클립보드 복사에 실패하였습니다.');
    }
  };

  return (
    <>
      <Dim
        zIndex={200}
        onClick={onCloseShareCard}
        isDisplay={props.shareCardPopupState === 'OPEN'}
      />
      <SlideUpPopupWrapper isOpen={props.shareCardPopupState} overflow="N">
        <StyledShareCard>
          <button
            className="close_btn"
            onClick={onCloseShareCard}
            aria-label="닫기"
          >
            <IcoAppCardClose />
          </button>
          <div className="card_header" onClick={onCloseShareCard} aria-hidden>
            <div />
          </div>
          <div className="card_box">
            <h2 className="card_title">공유하기</h2>
            <div className="card_content">
              <button className="share_btn" onClick={handleClickShareKaKao}>
                <IcoShareKakao />
                <p>카카오톡</p>
              </button>
              <CopyToClipboard text={copyUrl} onCopy={handleClickcopyUrl}>
                <button className="share_btn">
                  <IcoCopyUrl />
                  <p>URL 복사</p>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </StyledShareCard>
      </SlideUpPopupWrapper>
    </>
  );
}

export default ShareCard;
