import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actionNoneMainMenu } from 'src/redux/actions/mainActions';
import { StyledSideMenuList } from '../styles';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { SUPER_CHECK } from 'src/utils/constants';
import { gaEvent } from 'src/utils/ga';

function SideMenuPointSection(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mainStoreState] = useSelectorEqual((state: RootState) => [
    state.user.mainStoreState,
  ]);

  const handleClickSnowPoint = () => {
    setGaEvent('스노우플랜');
    navigate('/snowpoint/save');
    closePopup();
  };

  const handleClickSmartReceipt = () => {
    setGaEvent('스마트영수증');
    navigate('/receipt/list');
    closePopup();
  };

  const handleClickScanGo = () => {
    setGaEvent('스캔앤고결제내역');
    navigate('/scango/historylist');
    closePopup();
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `전체메뉴 팝업 ${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };

  const closePopup = () => {
    dispatch(actionNoneMainMenu());
  };

  return (
    <>
      {!SUPER_CHECK(mainStoreState.str_cd) && (
        <StyledSideMenuList>
          {mainStoreState.str_cd && (
            <ul>
              <span>포인트/결제</span>
              <li onClick={handleClickSnowPoint}>스노우플랜</li>
              <li onClick={handleClickSmartReceipt}>스마트 영수증</li>
              <li onClick={handleClickScanGo}>SCAN & GO 결제내역</li>
            </ul>
          )}
          <div className="base_line" />
        </StyledSideMenuList>
      )}
    </>
  );
}

export default SideMenuPointSection;
