import { thejamsilFont } from 'src/styles/font';
import { rem } from 'src/styles/function';
import styled, { keyframes } from 'styled-components';

/**
 * @name 메인헤더 스타일 컴포넌트
 */
export const StyledHeader = styled.header`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 50;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: ${rem(10.5)} ${rem(20)} ${rem(8)};
  .header_box {
    ${thejamsilFont('medium')}
    display: flex;
    justify-content: space-between;
    h3 {
      margin: auto 0px;
      i {
        width: ${rem(20)};
        height: ${rem(20)};
        display: inline-block;
        vertical-align: bottom;
        margin-left: 0.3rem;
      }
      .desc {
        ${thejamsilFont('light')}
        font-size: ${rem(12)};
        color: #666;
        margin-left: 0.5rem;
        vertical-align: middle;
      }
    }
    button {
      width: ${rem(30)};
      height: ${rem(30)};
      :first-child {
        margin-right: ${rem(10)};
      }
    }
  }
`;

/**@name 서브헤더 스타일 컴포넌트 */
export const StyledSubHeader = styled.header`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  height: ${rem(50)};
  padding: 17px 20px;
  align-items: center;
  button {
    width: 2rem;
    height: 2rem;
  }
  .btn_area {
    position: relative;
  }
  .back_btm {
    margin-right: ${rem(10)};
  }
  .ico-home {
    position: absolute;
    left: ${rem(40)};
  }
  h3 {
    text-align: center;
    ${thejamsilFont('medium')}
    font-size : ${rem(18)};
    margin: 0 auto;
    width: 50%;
    padding-left: ${rem(5)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    color: #e60012;
  }
`;

/**
 * @name 서브레이아웃 스타일 컴포넌트
 */
export const StyledSubLayout = styled.main`
  margin-top: ${rem(50)};
  padding-bottom: ${rem(175)};
`;

export const StyledScanSubLayout = styled.main`
  margin-top: ${rem(50)};
`;

/**
 * @name 스캔앤고 서브헤더 스타일 컴포넌트
 */
export const StyledSubHeaderScan = styled.header`
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 50;
  padding: ${rem(16.5)} 0;
  margin-bottom: ${rem(30)};
  display: flex;
  left: 0;
  top: 0;
  h3 {
    ${thejamsilFont('regular')};
    text-align: center;
    font-size: ${rem(18)};
    width: 85%;
    margin: auto 0 auto ${rem(-20)};
  }
  button {
    margin: auto 0;
    width: ${rem(30)};
    height: ${rem(30)};
    margin-left: ${rem(20)};
  }
`;

/**
 * @name 푸터 키프레임
 */
export const footerKeyframe = (isScroll: boolean) => keyframes`
  0% {
    bottom: ${isScroll ? '-5rem' : '1rem'}
  }
  100%{
    bottom: ${isScroll ? '1rem' : '-7rem'}
  }
`;

/**
 * @name 푸터 스타일 컴포넌트
 * @param isScroll 스크롤 여부
 */
export const StyledFooter = styled.footer<{ isScroll: boolean }>`
  animation: ${props => footerKeyframe(props.isScroll)} 0.2s 0s ease 1
    ${props => (props.isScroll ? 'backwards' : 'forwards')};
  display: block;
  position: fixed;
  bottom: 1rem;
  width: 100%;
  z-index: 89;
  .footer_wrapper {
    background-color: white;
    border-radius: 10px;
    width: 91%;
    margin: auto;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 1;
        text-align: center;
        font-size: ${rem(12)};
        ${thejamsilFont('bold')}
        margin: ${rem(14)} 0 ${rem(12.5)} ${rem(0.5)};
        .footer_ico {
          width: ${rem(24)};
          height: ${rem(24)};
          margin: auto auto ${rem(7)} auto;
          &.point {
            width: ${rem(28)};
            height: ${rem(24)};
          }
          .red {
            position: absolute;
            transform: translateX(-30%) translateY(-55%);
            display: block;
            width: 3.6rem;
            height: 3.6rem;
          }
        }
      }
    }
  }
`;

/**
 * @name 푸터 키프레임
 */
export const scrollTopKeyframe = (isView: string) => keyframes`
  from {
        opacity: ${isView === 'Y' ? '0' : '1'};
    }
    to {
        opacity: ${isView === 'Y' ? '1' : '0'};
    }
`;

/**
 * @name 스크롤탑 버튼 스타일 컴포넌트
 * @param isView 노출 여부
 */
export const StyledScrollTopButton = styled.button<{
  isView: string;
  openFooter: boolean;
}>`
  width: ${rem(42.5)};
  height: ${rem(42.5)};
  animation: ${props => props.isView && scrollTopKeyframe(props.isView)} 0.2s;
  opacity: ${props => (props.isView === 'Y' ? '1' : '0')};
`;

/**
 * @name 푸터(메인) 스타일 컴포넌트
 * @param isScroll 스크롤 여부
 */
export const StyledFooterMain = styled.footer`
  display: block;
  position: fixed;
  bottom: 1rem;
  width: 100%;
  z-index: 89;
  .footer_wrapper {
    background-color: white;
    border-radius: 10px;
    width: 91%;
    margin: auto;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 1;
        text-align: center;
        font-size: ${rem(12)};
        ${thejamsilFont('bold')}
        margin: ${rem(14)} 0 ${rem(12.5)} ${rem(0.5)};
        .footer_ico {
          width: ${rem(24)};
          height: ${rem(24)};
          margin: auto auto ${rem(7)} auto;
          &.point {
            width: ${rem(28)};
            height: ${rem(24)};
          }
          .red {
            position: absolute;
            transform: translateX(-30%) translateY(-55%);
            display: block;
            width: 3.6rem;
            height: 3.6rem;
          }
        }
      }
    }
  }
`;

/**
 * @name 메인 점포 운영정보 스타일 컴포넌트
 */
export const StyledHeaderStoreInfo = styled.div`
  .store_info {
    display: flex;
    align-items: center;

    span {
      font-size: ${rem(12)};
      color: ${({ theme }) => theme.colors.gray02};
    }

    .chip {
      line-height: ${rem(20)};
      font-weight: bold;
      text-align: center;
      display: inline-block;
      color: ${({ theme }) => theme.colors.red};
      width: ${rem(42)};
      box-sizing: border-box;
      border: 1px solid;
      border-color: ${({ theme }) => theme.colors.red};
      border-radius: ${rem(4)};
      margin-right: ${rem(8)};
    }

    .openTime {
      .time {
        margin-right: ${rem(4)};
      }

      .holiday {
        &:nth-child(2) {
          &::before {
            display: inline-block;
            content: '휴무일:';
            margin-right: ${rem(2)};
          }
        }

        &:not(:last-child) {
          margin-right: ${rem(2)};

          &::after {
            display: inline-block;
            content: ',';
          }
        }
      }
    }
  }
`;

/**
 * @name 푸터 버튼 스타일 컴포넌트
 * @param isView 노출 여부
 */
export const StyledFooterButton = styled.div<{
  openFooter: boolean;
}>`
  right: ${rem(12)};
  bottom: ${props =>
    props.openFooter ? '7.5rem' : '2rem'}; // 마진 디자인 맞춰서 조정
  position: fixed;
  z-index: 50;
  height: ${rem(95)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

/**
 * @name 이벤트공유 버튼 스타일 컴포넌트
 * @param openFooter 푸터 노출 여부
 */
export const StyledEventShareButton = styled.button`
  width: ${rem(42.5)};
  height: ${rem(42.5)};
  margin-top: ${rem(10)}; // 마진 디자인 맞춰서 고정
`;

/**
 * @name 공유카드 스타일 컴포넌트
 */
export const StyledShareCard = styled.div`
  position: relative;
  .close_btn {
    position: absolute;
    width: ${rem(40)};
    height: ${rem(40)};
    top: ${rem(-60)};
    left: 45%;
  }
  .card_header {
    padding-top: ${rem(19)};
    div {
      margin: auto;
      width: ${rem(110)};
      height: ${rem(5)};
      border-radius: ${rem(10)};
      background-color: #d3d3d3;
    }
  }
  .card_box {
    .card_title {
      ${thejamsilFont('bold')}
      font-size: ${rem(20)};
      color: #000000;
      padding-left: ${rem(24.5)};
    }
    .card_content {
      padding: ${rem(21.5)} ${rem(80.5)} ${rem(44.5)};
      display: flex;
      justify-content: space-between;

      .share_btn {
        width: ${rem(64.5)};
        height: ${rem(64)};

        p {
          margin-top: ${rem(9.5)};
          ${thejamsilFont('regular')}
          font-size: ${rem(14)};
          text-align: center;
        }
      }
    }
  }
`;
