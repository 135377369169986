import React from 'react';

import { StyledSideMenuList } from '../styles';
import { bridgeOpeBottleBunkerApp, bridgeOpenBrowser } from 'src/utils/bridge';
import { gaEvent } from 'src/utils/ga';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { useQuery } from '@tanstack/react-query';
import { apiOkadoOpenInfo } from 'src/api/apiAssistant';
import { E_QUERY_KEY } from 'src/utils/queryKey';
import { E_MAIN_MENU_STATE } from 'src/redux/types/mainTypes';
import { E_EC_DEEPLINK_STATE } from 'src/utils/enum';

function SideMenuBrandSection(): React.ReactElement {
  const [mainStoreState, mainMenuState] = useSelectorEqual(
    (state: RootState) => [state.user.mainStoreState, state.main.mainMenuState],
  );

  // 오카도 오픈 정보 조회
  const { data: ospOpenInfo } = useQuery(
    [...E_QUERY_KEY.ASSISTANT_DETAIL_OSPINFO],
    () => apiOkadoOpenInfo({ event_type: '17' }),
    {
      enabled: mainMenuState === E_MAIN_MENU_STATE.OPEN,
    },
  );

  const handleClickLotteOn = (btnName: string) => {
    setGaEvent(btnName);

    if (ospOpenInfo.deeplink_show_dvs === E_EC_DEEPLINK_STATE.ZETTA) {
      // 오카도 앱 열기
      if (process.env.REACT_APP_OSP_ORIGIN) {
        bridgeOpenBrowser(process.env.REACT_APP_OSP_ORIGIN);
      }
    } else if (ospOpenInfo.deeplink_show_dvs === E_EC_DEEPLINK_STATE.MALL) {
      // 롯데마트몰 앱 열기
      bridgeOpenBrowser(
        `https://www.lotteon.com/p/display/main/lottemart?mall_no=4&`,
      );
    }
  };
  const handleClickBottle = () => {
    setGaEvent('보틀벙커');
    bridgeOpeBottleBunkerApp();
  };

  const handleClickToy = () => {
    setGaEvent('토이저러스');
    bridgeOpenBrowser(
      `https://www.lotteon.com/p/display/main/toysrus?mall_no9&ch_no=100195&ch_dtl_no=1000617`,
    );
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `전체메뉴 팝업 ${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };

  return (
    <StyledSideMenuList>
      {mainStoreState.str_cd && (
        <ul>
          <span>롯데마트 브랜드</span>
          {ospOpenInfo && ospOpenInfo.deeplink_show_dvs && (
            <>
              {ospOpenInfo.deeplink_show_dvs === E_EC_DEEPLINK_STATE.MALL && (
                <li onClick={() => handleClickLotteOn('롯데마트몰')}>
                  롯데마트몰
                </li>
              )}
              {ospOpenInfo.deeplink_show_dvs === E_EC_DEEPLINK_STATE.ZETTA && (
                <li onClick={() => handleClickLotteOn('롯데마트 제타')}>
                  롯데마트 제타
                </li>
              )}
            </>
          )}
          <li onClick={handleClickBottle}>보틀벙커</li>
          <li onClick={handleClickToy}>토이저러스</li>
        </ul>
      )}
      <div className="base_line" />
    </StyledSideMenuList>
  );
}

export default SideMenuBrandSection;
