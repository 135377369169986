import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionNoneMainMenu } from 'src/redux/actions/mainActions';

import { StyledSideMenuList } from '../styles';
import { bridgeOpenBrowser } from 'src/utils/bridge';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { SUPER_CHECK } from 'src/utils/constants';
import { getMembersTkn } from 'src/utils/sso';
import { gaEvent } from 'src/utils/ga';

function SideMenuConvenienceSection(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mainStoreState] = useSelectorEqual((state: RootState) => [
    state.user.mainStoreState,
  ]);

  const handleClickLeafletNews = () => {
    setGaEvent('점포소식보기');
    navigate('/leaflet/news');
    dispatch(actionNoneMainMenu());
  };

  const handleClickTenant = () => {
    setGaEvent('입점매장');
    navigate('/tenant/list');
    dispatch(actionNoneMainMenu());
  };
  const handleClickCenter = () => {
    setGaEvent('문화센터');
    const membersTkn = getMembersTkn();
    bridgeOpenBrowser(
      `https://m.culture.lottemart.com/cu/common/main.do?ssoTkn=${membersTkn.ssoTkn}`,
    );
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `전체메뉴 팝업 ${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };

  return (
    <>
      {!SUPER_CHECK(mainStoreState.str_cd) && (
        <StyledSideMenuList>
          {mainStoreState.str_cd && (
            <ul>
              <span>매장편의</span>
              <li onClick={handleClickLeafletNews}>점포 소식 보기</li>
              <li onClick={handleClickTenant}>입점 매장</li>
              <li onClick={handleClickCenter}>문화센터</li>
            </ul>
          )}
          <div className="base_line" />
        </StyledSideMenuList>
      )}
    </>
  );
}

export default SideMenuConvenienceSection;
