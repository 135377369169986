import moment from 'moment';
import { TLoginMembersRes } from 'src/containers/AutoLogin/types';
import { TAgmtInfo } from 'src/types/types';
import { isAndWebView, isIOSWebView } from './constants';
import {
  bridgeDeleteCache,
  bridgeHideSplash,
  bridgeLogout,
  bridgeSetAutoLogin,
  bridgeSetKeyValue,
} from './bridge';

/**
 * @name 롯데멤버스 토큰 저장하기
 * @param membersTkn {acesTkn:"",rnwTkn:"",ssoTkn:""}
 */
export const storageMembersTkn = membersTkn => {
  localStorage.setItem('membersTkn', JSON.stringify(membersTkn));
};

/**
 * @name 마트GO 토큰 저장하기
 * @param martgoTkn {access_token:"",refresh_token:""}
 */
export const storageMartgoTkn = martgoTkn => {
  localStorage.setItem('martgoTkn', JSON.stringify(martgoTkn));
};

/**
 * @name PSP ID 저장하기
 */
export const storagePspId = psp_id => {
  localStorage.setItem('psp_id', psp_id);
  bridgeSetKeyValue('psp_id', psp_id);
};

/**
 * @name 롯데멤버스 토큰 가져오기
 */
export const getMembersTkn = (): any => {
  const strMembersTkn = localStorage.getItem('membersTkn');
  const membersTkn = strMembersTkn
    ? JSON.parse(strMembersTkn)
    : { acesTkn: '', rnwTkn: '', ssoTkn: '' };
  return membersTkn;
};

/**
 * @name 마트GO 토큰 가져오기
 */
export const getMartgoTkn = (): any => {
  const strMartgoTkn = localStorage.getItem('martgoTkn');
  const martgoTkn = strMartgoTkn
    ? JSON.parse(strMartgoTkn)
    : { access_token: '', refresh_token: '' };
  return martgoTkn;
};

/**
 * @name 스노우플랜 ID 가져오기
 */
export const getPspId = (): any => {
  const psp_id = localStorage.getItem('psp_id');
  return psp_id;
};

/**
 * @name 멤버스 자동로그인 등록일시 가져오기
 */
export const getAutoLgnRgDtti = (): any => {
  const auto_login_savedate = localStorage.getItem('AUTO_LOGIN_SAVEDATE');
  const crntTime = moment().format('YYYYMMDDHHmmss');
  const autoLgnRgDtti = auto_login_savedate ? auto_login_savedate : crntTime;

  localStorage.setItem('AUTO_LOGIN_SAVEDATE', autoLgnRgDtti);
  return autoLgnRgDtti;
};

/**
 * @name 약관제목 추출
 * @param agmt_type 약관 타입
 * @param agmtList 약관 목록
 */
export const getAgmtTitle = (
  agmt_type: string,
  agmtList: TAgmtInfo[],
  hiddenMust?: boolean,
): string => {
  if (!agmtList || agmtList.length < 1) return '';

  const selectAgmt = agmtList.filter(agmt => agmt.agmt_type === agmt_type)[0];

  const strMustYn =
    selectAgmt && selectAgmt.must_yn === 'Y'
      ? '(필수)'
      : selectAgmt && selectAgmt.must_yn === 'N'
      ? '(선택)'
      : '';
  return selectAgmt
    ? `${selectAgmt.agmt_nm} ${!hiddenMust ? strMustYn : ''}`
    : '';
};

/**
 * @name 약관설명 추출
 * @param agmt_type 약관 타입
 * @param agmtList 약관 목록
 */
export const getAgmtDesc = (
  agmt_type: string,
  agmtList: TAgmtInfo[],
): string => {
  if (!agmtList || agmtList.length < 1) return '';

  const selectAgmt = agmtList.filter(agmt => agmt.agmt_type === agmt_type)[0];
  return selectAgmt ? `${selectAgmt.agmt_desc}` : '';
};

/**
 * @name 로그인 전 단말정보 조회 화면 호출
 */
export const moveSsoFdsPage = redirect_path => {
  let _redirect_path = '';
  if (redirect_path.indexOf('?') > -1) {
    _redirect_path = `${redirect_path}&fdsPageYn=Y`;
  } else {
    _redirect_path = `${redirect_path}?fdsPageYn=Y`;
  }
  location.replace(
    `${process.env.REACT_APP_BASE_API_JSP}/getFdsDta.sso?redirect_path=${_redirect_path}`,
  );
};

/**
 * @name 로그인 전 단말정보 조회 값 스토리지에 저장
 */
export const getKeyFdsInfo = () => {
  try {
    const callbackNameWdata = 'callbackGetKeyValueWdata';
    const callbackNameUkey = 'callbackGetKeyValueUkey';
    if (isIOSWebView) {
      webkit.messageHandlers.getKeyValue.postMessage({
        key: 'membs_login_wdata',
        callback: callbackNameWdata,
      });
      webkit.messageHandlers.getKeyValue.postMessage({
        key: 'membs_login_ukey',
        callback: callbackNameUkey,
      });
    } else if (isAndWebView) {
      window.binder?.getKeyValue('membs_login_wdata', callbackNameWdata);
      window.binder?.getKeyValue('membs_login_ukey', callbackNameUkey);
    }

    window[callbackNameWdata] = (key, value) => {
      localStorage.setItem('wdata', value);
    };

    window[callbackNameUkey] = (key, value) => {
      localStorage.setItem('ukey', value);
    };
  } catch (e) {
    console.log('error bridge callbackGetKeyValueFdsInfo');
  }
};

/**
 * @name 로그아웃 화면 호출
 */
export const moveSsoCallLogout = () => {
  const membersTkn = getMembersTkn();
  if (!membersTkn) {
    //멤버스 토큰이 없는 경우 스토리지 및 토큰 삭제 후 로그아웃 처리
    bridgeLogout();
    bridgeDeleteCache('');
    localStorage.clear();
    sessionStorage.clear();
    return;
  }
  //jsp에서 ==값 치환 필요
  const acesTkn = membersTkn.acesTkn.replace(/=/g, '_ES_');

  //스토리지 삭제
  localStorage.clear();
  sessionStorage.clear();

  location.href = `${process.env.REACT_APP_BASE_API_JSP}/callLogout.sso?acesTkn=${acesTkn}`;
};

/**
 * @name 로그인 전 통합회원제 화면호출 (회원가입, ID/PW 찾기)
 * @param linkJoin 회원가입
 * @param linkFindID 아이디 찾기
 * @param linkFindPW 비밀번호 찾기
 */
export const moveSsoInitPage = (callType, redirect_path) => {
  bridgeHideSplash();
  const _redirect_path = redirect_path.replace('&', '_amp_');
  const initLangCode = localStorage.getItem('initLangCode') || 'ko';
  location.href = `${process.env.REACT_APP_BASE_API_JSP}/callScreenInit.sso?callType=${callType}&redirect_path=${_redirect_path}&langCode=${initLangCode}`;
};

/**
 * @name 로그인 비밀번호 변경 캠페인
 */
export const moveSsoPwChangePage = member_yn => {
  bridgeHideSplash();
  location.href = `${process.env.REACT_APP_BASE_API_JSP}/pwCampBridge.sso?member_yn=${member_yn}`;
};

/**
 * @name 로그인 후 처리 통합회원제 화면호출
 */
export const moveSsoAfterPage = (
  callType,
  moveParams: { acesTkn; ssoTkn; onlCstTpC; frnYn; rspDtc; redirect_path },
) => {
  bridgeHideSplash();
  //jsp에서 ==값 치환 필요
  const _acesTkn = moveParams.acesTkn
    ? moveParams.acesTkn.replace(/=/g, '_ES_')
    : '';
  let _search = `?callType=${callType}&acesTkn=${_acesTkn}&redirect_path=${moveParams.redirect_path}`;
  if (moveParams.ssoTkn) {
    _search += `&ssoTkn=${moveParams.ssoTkn}`;
  }

  if (moveParams.onlCstTpC) {
    _search += `&onlCstTpC=${moveParams.onlCstTpC}`;
  }

  if (moveParams.frnYn) {
    _search += `&frnYn=${moveParams.frnYn}`;
  }

  if (moveParams.rspDtc) {
    _search += `&rspDtc=${moveParams.rspDtc}`;
  }
  location.href = `${process.env.REACT_APP_BASE_API_JSP}/callScreenAfter.sso${_search}`;
};

/**
 * @name 로그인 후 처리 - 로그인 응답 오류 처리 공통
 * @parma redirect_path 리턴 url
 */

export const membersAfterProcess = (
  ssoRes: TLoginMembersRes,
  redirect_path: string,
  setLoginErrorInfo?: ({ isLoginError, loginErrorMsg }) => void,
) => {
  if (!ssoRes.rspC || !ssoRes.rspDtc) return;

  const errorMsg =
    '[' + ssoRes.rspC + '-' + ssoRes.rspDtc + '] ' + ssoRes.rspMsgCn ||
    '오류가 발생하였습니다. 잠시 후 다시 시도해주세요.';

  const moveParams = {
    acesTkn: ssoRes.acesTkn,
    ssoTkn: ssoRes.ssoTkn,
    onlCstTpC: ssoRes.onlCstTpC,
    frnYn: ssoRes.frnYn,
    rspDtc: ssoRes.rspDtc,
    redirect_path: redirect_path,
  };

  if (ssoRes.rspC === '44') {
    bridgeSetAutoLogin('N');
    if (ssoRes.rspDtc === '401') {
      setLoginErrorInfo
        ? setLoginErrorInfo({
            isLoginError: true,
            loginErrorMsg: '입력하신 아이디 또는 비밀번호를 다시 확인해주세요.',
          })
        : alert('입력하신 아이디 또는 비밀번호를 다시 확인해주세요.');
    } else if (ssoRes.rspDtc == '404') {
      // 부정로그인 대상 - 부정로그인 해제 화면 호출
      // CS : alert 미노출
      moveSsoAfterPage('releaseLogin', moveParams);
    } else if (ssoRes.rspDtc == '405') {
      // 비밀번호 오류횟수 5회초과 - 비밀번호 찾기 화면 호출
      alert(errorMsg);
      moveSsoInitPage('linkFindPW', moveParams.redirect_path);
    } else if (
      ssoRes.rspDtc == '406' ||
      ssoRes.rspDtc == '407' ||
      ssoRes.rspDtc == '408'
    ) {
      // Lpoin, 제휴사 휴면회원- 휴면해제 화면 호출
      alert(errorMsg);
      moveSsoAfterPage('dormancyLogin', moveParams);
    } else if (ssoRes.rspDtc == '410') {
      // 임시 비밀번호 발급상태- 새 비밀번호 입력 화면 호출
      alert(errorMsg);
      moveSsoAfterPage('newPassword', moveParams);
    } else if (ssoRes.rspDtc == '414') {
      // 정회원 전환 가능 대상 - 정회원 전환 화면 호출
      alert(errorMsg);
      moveSsoAfterPage('regularMember', moveParams);
    } else if (ssoRes.rspDtc == '419') {
      // 제휴사 약관 미동의 회원 - 이중제휴사 약관동의 화면 호출
      alert(errorMsg);
      moveSsoAfterPage('termsAccept', moveParams);
    } else if (ssoRes.rspDtc == '420') {
      // 제휴사 약관 미동의 회원 - 이중제휴사 약관동의 화면 호출 (14세미만)
      alert(errorMsg);
      moveSsoAfterPage('termsAcceptChild', moveParams);
    } else if (ssoRes.rspDtc == '415') {
      // 성인만 이용 가능
      setLoginErrorInfo
        ? setLoginErrorInfo({
            isLoginError: true,
            loginErrorMsg: '(44-415) 성인만 이용가능한 서비스입니다.',
          })
        : alert('(44-415) 성인만 이용가능한 서비스입니다.');
    } else if (ssoRes.rspDtc == '430') {
      // CI 로그인에서 발생 가능 - 온라인ID목록 관련
      alert(
        '(44-430) 중복된 회원 정보가 존재합니다. L.POINT 고객센터를 통해 회원 정보를 수정해주세요.',
      );
    } else if (ssoRes.rspDtc == '439') {
      // 소셜로그인 맵핑 정보 없음(엘포인트 비회원) - 엘포인트 회원가입으로 이동
      moveSsoInitPage('linkJoin', redirect_path);
    } else if (
      ssoRes.rspDtc == '705' ||
      ssoRes.rspDtc == '720' ||
      ssoRes.rspDtc == '722'
    ) {
      // 705 : 등록된 사용자가 아님 - 간편로그인등록 화면 호출(지문)
      // 720 : 등록된 생체인증 정보 없음 (기기에 지문이 없었거나 기기변경 시)
      // 722 : 기기에 등록된 생체인증 정보가 변경 됨
      // iOS는 앱에서 처리, Android만 여기 탐
      moveSsoAfterPage('setEasyLogin', moveParams);
    } else {
      //후처리 외 오류코드

      // 에러메세지 미노출 조건
      // 422:생체인증 중복등록 오류 및 초기화(native alert)
      // 716:디바이스에 등록된 지문 없음(native alert)
      if (ssoRes.rspDtc == '422' || ssoRes.rspDtc == '716') return;

      setLoginErrorInfo
        ? setLoginErrorInfo({
            isLoginError: true,
            loginErrorMsg: errorMsg,
          })
        : alert(errorMsg);
    }
  } else {
    bridgeSetAutoLogin('N');
    setLoginErrorInfo
      ? setLoginErrorInfo({
          isLoginError: true,
          loginErrorMsg: errorMsg,
        })
      : alert(errorMsg);
  }
};
